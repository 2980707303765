import { prefix, uicoreCustomEvent, DOC, setFocus, createElement, each, jsonOptionsInit } from "./utilities.js";

export default function Dropdown(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set option
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.persist = element.dataset.persist ? element.dataset.persist : options.persist ? options.persist : false;
    // constants, event targets, strings
    var self = this,
        stringDropdown = "Dropdown",
        parent = element.parentNode,
        isMultiSelect = false,
        relatedTarget = null,
        menu = parent.querySelector("." + prefix + "button-dropdown-container"),
        menuItems,
        itemsChecked,
        currentText,
        filters,
        // preventDefault on empty anchor links
        preventEmptyAnchor = function (anchor) {
            if (
                (anchor && anchor.tagName === "A" && anchor.href && anchor.href.slice(-1) === "#") ||
                (anchor &&
                    anchor.parentNode &&
                    anchor.parentNode.tagName === "A" &&
                    anchor.parentNode.href &&
                    anchor.parentNode.href.slice(-1) === "#")
            ) {
                this.preventDefault();
            }
        },
        // toggle dismissible events
        toggleDismiss = function () {
            if (element.open) {
                element.addEventListener("click", dismissHandler, false);
                element.addEventListener("keydown", preventScroll, false);
                menu.addEventListener("keydown", preventScroll, false);
                menu.addEventListener("click", dismissHandler, false);
                window.addEventListener("click", windowClickHandler, true);
            } else {
                element.removeEventListener("click", dismissHandler, false);
                element.removeEventListener("keydown", preventScroll, false);
                menu.removeEventListener("keydown", preventScroll, false);
                menu.removeEventListener("click", dismissHandler, false);
                window.removeEventListener("click", windowClickHandler, true);
            }
        },
        // handlers
        dismissHandler = function (e) {
            var eventTarget = e.target,
                hasData = eventTarget && (stringDropdown in eventTarget || stringDropdown in eventTarget.parentNode);
            if ((eventTarget === menu || menu.contains(eventTarget)) && (options.persist || hasData)) {
                return;
            } else {
                relatedTarget = eventTarget === element || element.contains(eventTarget) ? element : null;

                if (!eventTarget.classList.contains(prefix + "disabled")) {
                    hide();
                }
            }
            element.focus();
            preventEmptyAnchor.call(e, eventTarget);
        },
        formatDisplayCount = function () {
            var displayTxt;
            if (itemsChecked > 0) {
                displayTxt = " (" + itemsChecked + ")";
            } else {
                displayTxt = " ";
            }
            var checkedCounter = element.querySelector("." + prefix + "checked-presentation");
            if (checkedCounter != null) {
                checkedCounter.innerHTML = checkedCounter.innerHTML.replace(currentText, displayTxt);
                currentText = displayTxt;
            } else {
                checkedCounter = createElement("span", {
                    class: prefix + "checked-presentation " + prefix + "ml-1",
                    html: displayTxt,
                });
                currentText = checkedCounter.innerHTML;
                // element.insertBefore(checkedCounter,element.querySelector("svg." + prefix + "arrow-tri-solid-right"));
                element.querySelector(".dds__text-truncate").appendChild(checkedCounter);
            }
        },
        clickHandler = function (e) {
            relatedTarget = element;

            if (isMultiSelect && e.target.tagName === "INPUT") {
                if (e.target.checked) {
                    filters.push(e.target.name);
                    e.target.parentElement.parentElement.setAttribute("aria-checked", "true");
                    uicoreCustomEvent("Dropdown", "AddEvent", element, {
                        filters: filters,
                        filter: e.target.name,
                        filterInput: e.target,
                    });
                } else {
                    var index = filters.indexOf(e.target.name);
                    filters.splice(index, 1);
                    e.target.parentElement.parentElement.setAttribute("aria-checked", "false");
                    uicoreCustomEvent("Dropdown", "RemoveEvent", element, {
                        filters: filters,
                        filter: e.target.name,
                        filterInput: e.target,
                    });
                }
                self.recountChecked();

                // element.innerHTML = element.innerHTML.replace(currentText, displayTxt);
                // currentText = displayTxt;
                element.focus();
            } else if (
                e.target.tagName == "BUTTON" ||
                e.target.parentElement.tagName == "BUTTON" ||
                e.target.parentElement.parentElement.tagName == "BUTTON"
            ) {
                self.toggle();
                preventEmptyAnchor.call(e, e.target);
            }
        },
        preventScroll = function (e) {
            var key = e.which || e.keyCode;
            if (key === 38 || key === 40) {
                e.preventDefault();
            }
        },
        keyHandler = function (e) {
            var key = e.which || e.keyCode,
                activeItem = DOC.activeElement,
                isMenuOpen = menu.classList.contains(prefix + "show"),
                isSameElement = activeItem === element || activeItem.parentNode === menu,
                isInsideMenu = menu.contains(activeItem),
                isMenuItem = activeItem.parentNode === menu || activeItem.parentNode.parentNode === menu;

            if (isMenuItem || (isMenuOpen && isSameElement)) {
                // checking element with enter or spacebar
                if (isInsideMenu && (key === 13 || key === 32)) {
                    activeItem.firstElementChild.click();
                }

                // navigate up | down
                var idx = menuItems.indexOf(activeItem);
                idx =
                    key === 38
                        ? idx >= 1
                            ? idx - 1
                            : 0
                        : key === 40
                        ? idx < menuItems.length - 1
                            ? idx + 1
                            : menuItems.length - 1
                        : idx;
                menuItems[idx] && setFocus(menuItems[idx]);
            }
            if (
                ((menuItems.length && isMenuItem) || // menu has items
                    (!menuItems.length && (isInsideMenu || isSameElement)) || // menu might be a form
                    !isInsideMenu) && // or the focused element is not in the menu at all
                element.open &&
                key === 27 // menu must be open
            ) {
                self.toggle();
                relatedTarget = null;
                element.focus();
            }
            if (!isMenuOpen && !isMenuItem && (key === 40 || key === 13 || key === 32)) {
                //if menu is closed and hit enter or space on arrow btn, open menu and set focus to first item
                e.preventDefault();
                self.toggle();
                menuItems[0] && setFocus(menuItems[0]);
                relatedTarget = null;
            }
            if (isMenuOpen && (key === 9 || (e.shiftKey && e.keyCode == 9))) {
                //if menu is open then close menu on tab
                e.preventDefault();
                self.toggle();
                element.focus();
            }
        },
        // private methods
        show = function () {
            if (element.classList.contains(prefix + "btn-split-arrow")) {
                menu.style.minWidth = parent.offsetWidth + "px";
            } else if (element.classList.contains(prefix + "btn")) {
                menu.style.minWidth = element.offsetWidth + "px";
            }
            uicoreCustomEvent("DropDown", "ShowEvent", element);
            menu.classList.add(prefix + "show");
            parent.classList.add(prefix + "show");
            element.setAttribute("aria-expanded", true);
            uicoreCustomEvent("DropDown", "Shown", element);
            element.open = true;

            setTimeout(function () {
                toggleDismiss();
            }, 100);
        },
        hide = function () {
            uicoreCustomEvent("DropDown", "HideEvent", element);
            menu.classList.remove(prefix + "show");
            parent.classList.remove(prefix + "show");
            element.setAttribute("aria-expanded", false);
            uicoreCustomEvent("DropDown", "Hidden", element);
            element.open = false;

            setTimeout(function () {
                toggleDismiss();
                element.addEventListener("click", clickHandler, false);
            }, 100);
        },
        focusOutHandler = function (e) {
            var target = e.relatedTarget ? e.relatedTarget : e.target ? e.target : DOC.activeElement;
            if (!e.currentTarget.contains(target)) {
                if (e.currentTarget.classList.contains(prefix + "btn-dropdown")) {
                    relatedTarget = parent = e.currentTarget;
                } else {
                    relatedTarget = parent = e.currentTarget.parentNode;
                }
            } else {
                return;
            }
            hide();
            preventEmptyAnchor.call(e, relatedTarget);
        },
        windowClickHandler = function (e) {
            if (!parent.contains(e.target) || parent === e.target) {
                hide();
                preventEmptyAnchor.call(e, relatedTarget);
            }
        };

    // public methods
    this.toggle = function () {
        if (parent.classList.contains(prefix + "show") && element.open) {
            hide();
        } else {
            show();
        }
    };

    this.recountChecked = function () {
        itemsChecked = element.parentElement.querySelectorAll("." + prefix + "form-check-input:checked").length;
        formatDisplayCount();
    };

    // init
    if (!(stringDropdown in element)) {
        // set initial state to closed
        element.open = false;
        // prevent adding event handlers twice
        !("tabIndex" in menu) && menu.setAttribute("tabindex", "0"); // Fix onblur on Chrome | Safari
        element.addEventListener("click", clickHandler, false);
        parent.addEventListener("focusout", focusOutHandler, true);
        element.addEventListener("keydown", keyHandler, false);
        menu.addEventListener("keydown", keyHandler, false);
        if (element.parentElement.classList.contains("dds__multi-select")) {
            isMultiSelect = true;
        }

        var dropdownEls = menu.children;
        menuItems = [];
        each(dropdownEls, function (dropdownEl) {
            if (
                dropdownEl.children.length &&
                dropdownEl.children[0].tagName === "LI" &&
                !dropdownEl.children[0].firstElementChild.classList.contains(prefix + "disabled")
            ) {
                menuItems.push(dropdownEl.children[0]);
            } else if (
                dropdownEl.tagName === "LI" &&
                !dropdownEl.firstElementChild.classList.contains(prefix + "disabled")
            ) {
                menuItems.push(dropdownEl);
            }
        });
        if (isMultiSelect) {
            each(menuItems, function (menuItem) {
                menuItem.firstElementChild.addEventListener("click", clickHandler, true);
            });
            itemsChecked = 0;
            filters = [];
        }
    }

    element[stringDropdown] = self;
}
