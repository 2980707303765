import {
    getClosest,
    prefix,
    DOC,
    isSafari,
    isIOS,
    uicoreCustomEvent,
    createElement,
    each,
    setFocus,
    getFocusableElements,
    jsonOptionsInit,
} from "./utilities.js";
import Overflow from "./overflow.js";
import Collapse from "./collapse.js";

export default function Tab(element, options) {
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.lazyload = typeof options.lazyload === "boolean" ? options.lazyload : false;

    var self = this,
        stringTab = "Tab",
        tablist,
        activeTab = null,
        modalElement,
        withinModal,
        tabs,
        backButton,
        panels,
        delay,
        nested = false,
        focusableEls,
        thresholdMediaSize,
        tabContainer,
        accordionWrapper,
        smallMediaSize = window.matchMedia("(max-width: 767.98px)"),
        overflow,
        overflowContainer;

    // Key mappings for easy reference
    var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46,
        tab: 9,
        esc: 27,
    };

    // Add or substract depending on key pressed
    var direction = {
            37: -1,
            38: -1,
            39: 1,
            40: 1,
        },
        handleOverflowEvent = function (e) {
            overflow.detail = e.detail;
        },
        setFocusableElements = function () {
            focusableEls = getFocusableElements(modalElement);
            modalElement.firstFocusableEl = focusableEls[0];
            modalElement.lastFocusableEl = focusableEls[focusableEls.length - 1];
        },
        handleBackwardTab = function (e) {
            if (document.activeElement === modalElement.firstFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.lastFocusableEl);
            }
        },
        handleForwardTab = function (e) {
            if (document.activeElement === modalElement.lastFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.firstFocusableEl);
            }
        },
        handleModalKeydown = function (e) {
            switch (e.keyCode) {
                case keys.tab:
                    if (focusableEls.length === 1) {
                        e.preventDefault();
                        break;
                    }
                    if (e.shiftKey) {
                        handleBackwardTab(e);
                    } else {
                        handleForwardTab(e);
                    }
                    break;
                case keys.esc:
                    self.hide();
                    setFocus(activeTab);
                    break;
                default:
                    break;
            }
        },
        // When a tab is clicked, activateTab is fired to activate it
        clickEventListener = function (e) {
            var target = e.target;
            if (target === element && modalElement && !modalElement.classList.contains(prefix + "show")) {
                e.preventDefault();
            }
            self.show();
            activeTab = getClosest(target, "." + prefix + "tab-link");
            activateTab(activeTab, false);
        },
        // Handle keydown on tabs
        keydownEventListener = function (e) {
            var key = e.keyCode;

            switch (key) {
                case keys.end:
                    e.preventDefault();
                    // Activate last tab
                    if (overflow) {
                        overflow.clickEnd();
                    }
                    activateTab(tabs[tabs.length - 1]);
                    break;
                case keys.home:
                    e.preventDefault();
                    if (overflow) {
                        overflow.clickHome();
                    }
                    // Activate first tab
                    activateTab(tabs[0]);
                    break;

                // Up, down, left, and right keys are in keydown
                // because we need to prevent page scroll
                case keys.up:
                case keys.down:
                    determineOrientation(e);
                    break;
                case keys.left:
                case keys.right:
                    determineOrientation(e);
                    break;
            }
        },
        // Handle keyup on tabs
        keyupEventListener = function (e) {
            var key = e.keyCode;

            switch (key) {
                case keys.delete:
                    determineDeletable(e);
                    break;
            }
        },
        // When a tablist's aria-orientation is set to vertical,
        // only up and down arrow should function.
        // In all other cases only left and right arrow function.
        determineOrientation = function (e) {
            var key = e.keyCode;
            var vertical = tablist.getAttribute("aria-orientation") == "vertical";
            var proceed = false;

            if (vertical) {
                if (key === keys.up || key === keys.down) {
                    e.preventDefault();
                    proceed = true;
                }
            } else {
                if (key === keys.left || key === keys.right) {
                    e.preventDefault();
                    proceed = true;
                }
            }

            if (proceed) {
                switchTabOnArrowPress(e);
            }
        },
        // Find first parent of el with role="tablist"
        getParentTablist = function (el) {
            var role = "tablist";
            while (el && el.parentNode) {
                el = el.parentNode;
                if (el.getAttribute("role") && el.getAttribute("role") == role) {
                    return el;
                }
            }
            return null;
        },
        // Get all siblings of el with class="dds__tab-link"
        getSiblingTabs = function () {
            var siblingTabs = tablist.querySelectorAll("." + prefix + "tab-link");
            for (var i = 0; i < siblingTabs.length; i++) {
                siblingTabs[i].index = i;
            }
            return siblingTabs;
        },
        getTablistPanels = function (tab) {
            var controls = tab.getAttribute("aria-controls");
            var panelGroup = document.getElementById(controls).parentElement;
            var siblingPanels = panelGroup.children;

            return siblingPanels;
        },
        // Either focus the next, previous, first, or last tab
        // depening on key pressed
        switchTabOnArrowPress = function (e) {
            var pressed = e.keyCode;
            each(tabs, function (tab) {
                tab.addEventListener("focus", focusEventHandler);
            });

            if (direction[pressed]) {
                var target = e.target;
                if (target.index !== undefined) {
                    if (tabs[target.index + direction[pressed]]) {
                        var targetTab = target.index + direction[pressed];
                        if (overflow) {
                            if (targetTab < overflow.detail.left) {
                                overflow.clickLeft();
                            } else if (targetTab > overflow.detail.right) {
                                overflow.clickRight();
                            }
                        }
                        setTimeout(function () {
                            setFocus(tabs[targetTab]);
                        }, 125);
                    } else if (pressed === keys.left || pressed === keys.up) {
                        if (overflow) overflow.clickEnd();
                        focusLastTab();
                    } else if (pressed === keys.right || pressed == keys.down) {
                        if (overflow) overflow.clickHome();
                        focusFirstTab();
                    }
                }
            }
        },
        // Activates any given tab panel
        activateTab = function (tab, needsFocus) {
            needsFocus = needsFocus || true;

            // Deactivate all other tabs
            deactivateTabs(tab);

            // Remove tabindex attribute
            tab.removeAttribute("tabindex");

            // Set the tab as selected
            tab.setAttribute("aria-selected", "true");

            // Get the value of aria-controls (which is an ID)
            var controls = tab.getAttribute("aria-controls");

            tab.classList.add(prefix + "active");

            // Remove hidden attribute from tab panel to make it visible
            // document.getElementById(controls).removeAttribute('hidden');
            document.getElementById(controls).classList.add(prefix + "active");
            document.getElementById(controls).classList.add(prefix + "show");

            // Set focus when required
            if (needsFocus) {
                setFocus(tab);
            }
        },
        // Deactivate all tabs and tab panels
        deactivateTabs = function (tab) {
            panels = getTablistPanels(tab);
            each(tabs, function (tab) {
                tab.setAttribute("tabindex", "-1");
                tab.setAttribute("aria-selected", "false");
                tab.removeEventListener("focus", focusEventHandler);
                tab.classList.remove(prefix + "active");
                tab.classList.remove(prefix + "show");
            });

            each(panels, function (panel) {
                // panel.setAttribute('hidden', 'hidden');
                panel.classList.remove(prefix + "active");
                panel.classList.remove(prefix + "show");
            });
        },
        // Make a guess
        focusFirstTab = function () {
            setFocus(tabs[0]);
        },
        // Make a guess
        focusLastTab = function () {
            setFocus(tabs[tabs.length - 1]);
        },
        // Detect if a tab is deletable
        determineDeletable = function (e) {
            var target = e.target;

            if (target.getAttribute("data-deletable") !== null) {
                // Delete target tab
                deleteTab(e);

                // Update arrays related to tabs widget
                // generateArrays();

                // Activate the closest tab to the one that was just deleted
                if (target.index - 1 < 0) {
                    activateTab(tabs[0]);
                } else {
                    activateTab(tabs[target.index - 1]);
                }
            }
        },
        // Deletes a tab and its panel
        deleteTab = function (e) {
            var target = e.target;
            var panel = document.getElementById(target.getAttribute("aria-controls"));

            target.parentElement.removeChild(target);
            panel.parentElement.removeChild(panel);
        },
        // Determine whether there should be a delay
        // when user navigates with the arrow keys
        determineDelay = function () {
            var hasDelay = tablist.hasAttribute("data-delay");
            var delay = 0;

            if (hasDelay) {
                var delayValue = tablist.getAttribute("data-delay");
                if (delayValue) {
                    delay = delayValue;
                } else {
                    // If no value is specified, default to 300ms
                    delay = 300;
                }
            }

            return delay;
        },
        //
        focusEventHandler = function (e) {
            var target = e.target;

            setTimeout(checkTabFocus, delay, target);
        },
        // Only activate tab on focus if it still has focus after the delay
        checkTabFocus = function (target) {
            var focused = document.activeElement;

            if (target === focused) {
                activateTab(target, false);
            }
        },
        // triggers
        // unused eslint
        // triggerShow = function () {
        //     setFocus(modalElement);
        // },
        triggerHide = function () {
            modalElement.style.display = "";
        },
        createModal = function () {
            var modalBody = modalElement.querySelector("." + prefix + "modal-body");

            tabContainer = getClosest(tablist, "." + prefix + "tabs-container");
            var tabContentContainer;
            if (overflowContainer) {
                tabContentContainer = getClosest(tabContainer, "." + prefix + "tab-content", false);
            } else {
                tabContentContainer = tabContainer.querySelector("." + prefix + "tab-content");
            }

            if (modalBody.firstElementChild) {
                tabContentContainer.appendChild(modalBody.firstElementChild);
            }
            var tab = getClosest(event.target, "." + prefix + "tab-link");
            var controls = tab.getAttribute("aria-controls");
            var tabContent = document.getElementById(controls);

            setTimeout(function () {
                modalBody.appendChild(tabContent);
            }, 50);
        },
        dismissHandler = function (e) {
            if (modalElement.classList.contains(prefix + "show")) {
                self.hide();
                e.preventDefault();
                setFocus(activeTab);
            }
        },
        resizeHandler = function () {
            if (!smallMediaSize.matches && modalElement.classList.contains(prefix + "show")) {
                self.hide();
            }
        },
        createAccordions = function () {
            accordionWrapper = createElement("div", {
                class: prefix + "accordion " + prefix + "mb-3",
                id: tabs[0].id + "parent",
            });
            tabContainer.parentElement.insertBefore(accordionWrapper, tabContainer);
            each(tabs, function (tab) {
                var card = createElement("div", {
                    class: prefix + "accordion-card",
                });
                var cardHeader = createElement("div", {
                    class: prefix + "accordion-card-header",
                });
                var h5 = createElement("h5", {
                    class: prefix + "mb-0",
                });
                var accordionBtn = createElement("button", {
                    class: prefix + "accordion-btn " + prefix + "collapsed " + prefix + "d-flex",
                    type: "button",
                    role: "button",
                    data_toggle: prefix + "collapse",
                    data_target: "#" + tab.id + "accord", //Need to create IDs
                    data_parent: "#" + tabs[0].id + "parent",
                    aria_controls: tab.id + "accord",
                    aria_expanded: "false",
                });
                var chevron = createElement("i", {
                    class: prefix + "icons " + prefix + "chevron-right",
                    aria_hidden: "true",
                });
                var accordionSpan = createElement("span", {
                    class: prefix + "mx-2",
                    html: tab.innerText,
                    id: tab.id + "header",
                });

                accordionBtn.appendChild(chevron);
                accordionBtn.appendChild(accordionSpan);
                h5.appendChild(accordionBtn);
                cardHeader.appendChild(h5);
                card.appendChild(cardHeader);
                accordionWrapper.appendChild(card);
                var cardBodyWrapper = createElement("div", {
                    id: tab.id + "accord",
                    class: prefix + "collapse",
                    aria_labelledby: tab.id + "header",
                    role: "region",
                    aria_expanded: "false",
                });
                var cardBody = createElement("div", {
                    class: prefix + "accordion-card-body",
                    html: document.getElementById(tab.getAttribute("aria-controls")).innerHTML,
                });
                card.appendChild(cardBodyWrapper);
                cardBodyWrapper.appendChild(cardBody);
            });
            each(withinModal.querySelectorAll("[data-toggle='" + prefix + "collapse" + "']"), function (accordion) {
                new Collapse(accordion);
            });
        },
        handleResizeSmall = function () {
            if (thresholdMediaSize.matches) {
                var activeTab = tabContainer.querySelector("[aria-selected='true']");
                var sisterCollapseStr = activeTab.getAttribute("id") + "accord";
                var activateCollapse = accordionWrapper.querySelector("[data-target='#" + sisterCollapseStr + "']");
                if (activateCollapse && activateCollapse.classList.contains(prefix + "collapsed")) {
                    //Need to Activate the collapse
                    var prevActivated = accordionWrapper.querySelector("button[aria-expanded='true']");
                    var showBlock;
                    if (prevActivated) {
                        prevActivated.setAttribute("aria-expanded", false);
                        prevActivated.classList.add(prefix + "collapsed");
                        showBlock = getClosest(prevActivated, ".dds__collapse", false);
                        showBlock.classList.remove(prefix + "show");
                        showBlock.setAttribute("aria-expanded", "false");
                    }
                    activateCollapse.setAttribute("aria-expanded", true);
                    activateCollapse.classList.remove(prefix + "collapsed");
                    showBlock = getClosest(activateCollapse, ".dds__collapse", false);
                    showBlock.classList.add(prefix + "show");
                    showBlock.setAttribute("aria-expanded", "true");
                }
                accordionWrapper.classList.remove(prefix + "d-none");
                tabContainer.classList.add(prefix + "d-none");
            } else {
                var activeCollapse = accordionWrapper.querySelector("button[aria-expanded='true']");
                if (activeCollapse) {
                    var sisterTabStr = activeCollapse.getAttribute("aria-controls").slice(0, -6);
                    var tabToActivate = document.getElementById(sisterTabStr);
                    activateTab(tabToActivate);
                }
                accordionWrapper.classList.add(prefix + "d-none");
                tabContainer.classList.remove(prefix + "d-none");
            }
        };

    this.show = function () {
        if (window.innerWidth > 767.98) {
            return;
        }
        createModal();
        modalElement.style.display = "block";
        modalElement.setAttribute("aria-hidden", false);
        modalElement.classList.add(prefix + "show");

        if (isSafari && isIOS) {
            modalElement.classList.add(prefix + "is-safari");
        }

        if (DOC.body.classList.contains(prefix + "modal-open")) {
            nested = true;
        } else {
            DOC.body.classList.add(prefix + "modal-open");
            nested = false;
        }

        backButton = modalElement && modalElement.querySelector("[data-dismiss='" + prefix + "modal']");
        backButton.addEventListener("click", dismissHandler, false);
        smallMediaSize.addListener(resizeHandler);
        setTimeout(function () {
            setFocusableElements();
            setFocus(backButton);
        }, 50);
        modalElement.addEventListener("keydown", handleModalKeydown);
    };

    this.hide = function () {
        //Need to append tab content back to tabContentContainer
        var modalBody = modalElement.querySelector("." + prefix + "modal-body");
        // var tabContainer = getClosest(tablist, "." + prefix + "tabs-container", true);
        var tabContainer = getClosest(tablist, "." + prefix + "tabs-container");
        var tabContentContainer;
        if (overflowContainer) {
            tabContentContainer = getClosest(tabContainer, "." + prefix + "tab-content", false);
        } else {
            tabContentContainer = tabContainer.querySelector("." + prefix + "tab-content");
        }
        if (modalBody.firstElementChild) {
            tabContentContainer.appendChild(modalBody.firstElementChild);
        }
        modalElement.classList.add(prefix + "slide-right");
        modalElement.classList.remove(prefix + "show");
        modalElement.setAttribute("aria-hidden", true);
        if (isSafari && isIOS && modalElement.classList.contains(prefix + "is-safari")) {
            modalElement.classList.remove(prefix + "is-safari");
        }
        smallMediaSize.removeListener(resizeHandler);
        setTimeout(function () {
            triggerHide();
            if (!nested) {
                DOC.body.classList.remove(prefix + "modal-open");
            }
            backButton.removeEventListener("click", dismissHandler, false);
        }, 200);
        modalElement.removeEventListener("keydown", handleModalKeydown);
    };

    this.lazyLoad = function () {
        if (options.lazyload && overflow && !overflow.detail) {
            overflow.lazyLoad();
            overflow.detail = overflow.getCurrentDetails();
            overflowContainer.addEventListener("uicOverflowChangeEvent", handleOverflowEvent, false);
            uicoreCustomEvent("Tab", "LazyLoadEvent", element, { success: true });
        } else {
            uicoreCustomEvent("Tab", "LazyLoadEvent", element, {
                success: false,
                msg: "Tab cannot be lazy loaded. Check usage or avoid mulitple lazy loads.",
            });
        }
    };

    // init
    if (!(stringTab in element)) {
        tablist = getParentTablist(element);
        modalElement = element.dataset["target"] && DOC.getElementById(element.dataset["target"].substr(1));
        delay = determineDelay();
        tabs = getSiblingTabs();
        // prevent adding event handlers twice
        element.addEventListener("keydown", keydownEventListener);
        element.addEventListener("keyup", keyupEventListener);
        element.addEventListener("click", clickEventListener);
        overflowContainer = getClosest(element, "." + prefix + "container-overflow");
        if (overflowContainer) {
            if (!("overflow" in tablist)) {
                overflow = new Overflow(overflowContainer, {
                    lazyload: options.lazyload,
                });
                if (!options.lazyload) {
                    overflow.detail = overflow.getCurrentDetails();
                    overflowContainer.addEventListener("uicOverflowChangeEvent", handleOverflowEvent);
                }
                tablist["overflow"] = overflow;
            } else {
                overflow = tablist["overflow"];
            }
        }
        tabContainer = getClosest(tablist, "." + prefix + "tabs-container");
        withinModal = getClosest(element, "." + prefix + "modal-offcanvas", true);
        if (withinModal) {
            //check if tabs are within Modal
            var accordionExist = withinModal.querySelector("#" + element.id + "accord"); //check if accordions already been made
            if (!accordionExist) {
                createAccordions();
                if (withinModal.classList.contains(prefix + "modal-width-25")) {
                    thresholdMediaSize = window.matchMedia("(max-width: 3071.92px)"); //3071.92*0.25 = 767.98
                } else if (withinModal.classList.contains(prefix + "modal-width-50")) {
                    thresholdMediaSize = window.matchMedia("(max-width: 1535.96px)"); //1535.96*0.5 = 767.98
                } else if (withinModal.classList.contains(prefix + "modal-width-75")) {
                    thresholdMediaSize = window.matchMedia("(max-width: 1023.97px)"); //3071.92*0.25 = 767.98
                } else {
                    thresholdMediaSize = window.matchMedia("(max-width: 767.98px)");
                }
                thresholdMediaSize.addListener(handleResizeSmall);
                handleResizeSmall();
            }
        }
    }

    element[stringTab] = self;
}
