import { prefix, getSibling, isIE, createElement } from "../utilities";

export default function InputMask(element) {
    //, opts ) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    var wrap,
        stringInputMask = "InputMask",
        mNum = "XdDmMyY9",
        mChar = "_",
        spacing = 0,
        hasValue = false,
        charSet = element.dataset.charset,
        cursorMin = 0,
        createShell = function (t) {
            wrap = createElement("span", {
                class: prefix + "input-shell",
            });
            spacing = !t.getAttribute("set-cursor")
                ? spacing
                : isNaN(parseInt(t.getAttribute("set-cursor")))
                ? spacing
                : parseInt(t.getAttribute("set-cursor")) != 1
                ? spacing
                : 1;
            var mask = createElement("span", {
                    aria_hidden: "true",
                    id: t.getAttribute("id") + "Mask",
                }),
                emphasis = createElement("i"),
                tClass = t.getAttribute("class"),
                pTxt = hasValue ? t.value : t.getAttribute("placeholder"),
                placeholder = document.createTextNode(pTxt),
                errorDiv = getSibling(t, "#" + t.getAttribute("id") + "Feedback");

            t.setAttribute("maxlength", placeholder.length);
            t.setAttribute("data-placeholder", pTxt);
            t.removeAttribute("placeholder");

            if (!tClass || (tClass && tClass.indexOf("masked") === -1)) {
                t.setAttribute("class", tClass + " masked");
            }

            mask.appendChild(emphasis);
            mask.appendChild(placeholder);

            wrap.appendChild(mask);
            t.parentNode.insertBefore(wrap, t);
            wrap.appendChild(t);
            if (errorDiv) {
                wrap.appendChild(errorDiv);
            }
        },
        setValueOfMask = function (e) {
            var value = e.target.value,
                placeholder = e.target.getAttribute("data-placeholder");

            return "<i>" + value + "</i>" + placeholder.substr(value.length);
        },
        handleFocusEvent = function (e) {
            for (var s = 0; s < spacing; s++) {
                var char = e.target.getAttribute("data-placeholder").charAt(s);
                element.value += char;
                var evt;
                if (!isIE) {
                    evt = new KeyboardEvent("keyup", {
                        bubbles: true,
                        cancelable: true,
                        char: char,
                        key: "[",
                        shiftKey: true,
                    });
                } else {
                    evt = document.createEvent("KeyboardEvent");
                    evt.initKeyboardEvent("keyup", true, true, null, char, null, "", false, "");
                }
                element.dispatchEvent(evt);
            }
        },
        handleValueChange = function (e) {
            var id = e.target.getAttribute("id"),
                mask = wrap.querySelector("#" + id + "Mask"),
                italic = wrap.querySelector("#" + id + "Mask i"),
                cursor;

            if (e.target.value == italic.innerHTML) {
                return; // Continue only if value hasn't changed
            }

            setTimeout(function () {
                cursor = element.selectionEnd;
                e.target.value = handleCurrentValue(e);
                mask.innerHTML = setValueOfMask(e);

                if (e.keyCode != 8) {
                    //if the user is backspacing and then switches back to typing forward
                    if (!charSet.charAt(cursor - 1).match(".*[" + mNum + mChar + "].*")) {
                        while (
                            !charSet.charAt(cursor - 1).match(".*[" + mNum + mChar + "].*") &&
                            cursor < charSet.length
                        ) {
                            cursor++;
                        }
                    } else {
                        //if the user is typing forward
                        while (!charSet.charAt(cursor).match(".*[" + mNum + mChar + "].*") && cursor < charSet.length) {
                            cursor++;
                        }
                    }
                } else {
                    //if the user is backspacing
                    while (!charSet.charAt(cursor - 1).match(".*[" + mNum + mChar + "].*") && cursor > cursorMin) {
                        cursor--;
                    }
                    cursor = Math.max(cursor, cursorMin);
                }
                element.setSelectionRange(cursor, cursor);
            }, 50);
        },
        handleCurrentValue = function (e) {
            var isCharsetPresent = e.target.getAttribute("data-charset"),
                placeholder = isCharsetPresent || e.target.getAttribute("data-placeholder"),
                value = e.target.value,
                l = placeholder.length,
                newValue = "",
                i,
                j,
                isInt,
                isLetter,
                strippedValue;

            // strip special characters
            strippedValue = isCharsetPresent ? value.replace(/\W/g, "") : value.replace(/\D/g, "");

            for (i = 0, j = 0; i < l; i++) {
                isInt = !isNaN(parseInt(strippedValue[j]));
                isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false;
                var matchesNumber = mNum.indexOf(placeholder[i]) >= 0;
                var matchesLetter = mChar.indexOf(placeholder[i]) >= 0;
                if ((matchesNumber && isInt) || (isCharsetPresent && matchesLetter && isLetter)) {
                    newValue += strippedValue[j++];
                } else if (
                    (!isCharsetPresent && !isInt && matchesNumber) ||
                    (isCharsetPresent && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)))
                ) {
                    return newValue;
                } else {
                    newValue += placeholder[i];
                }
                // break if no characters left and the pattern is non-special character
                if (strippedValue[j] == undefined) {
                    if (i + 1 >= l || charSet.charAt(i + 1).match(".*[" + mNum + mChar + "].*") || e.keyCode == 8) {
                        break;
                    }
                }
            }
            if (e.target.getAttribute("data-valid-example")) {
                return validateProgress(e, newValue);
            }
            return newValue;
        },
        validateProgress = function (e, value) {
            var validExample = e.target.getAttribute("data-valid-example"),
                pattern = new RegExp(e.target.getAttribute("pattern")),
                placeholder = e.target.getAttribute("data-placeholder"),
                l = value.length,
                testValue = "";

            //convert to months
            if (l == 1 && placeholder.toUpperCase().substr(0, 2) == "MM") {
                if (value > 1 && value < 10) {
                    value = "0" + value;
                }
                return value;
            }
            // test the value, removing the last character, until what you have is a submatch
            for (var i = l; i >= 0; i--) {
                testValue = value + validExample.substr(value.length);
                if (pattern.test(testValue)) {
                    return value;
                } else {
                    value = value.substr(0, value.length - 1);
                }
            }

            return value;
        };

    // add event listeners
    // init
    if (!(stringInputMask in element)) {
        var parentNode = element.parentNode;
        element.value ? (hasValue = true) : (hasValue = false);
        if (!charSet) {
            throw new Error("Data charset is missing or invalid");
        }
        if (!charSet.charAt(0).match(".*[" + mNum + mChar + "].*")) {
            while (!charSet.charAt(cursorMin).match(".*[" + mNum + mChar + "].*")) {
                cursorMin++;
            }
        }
        if (!parentNode || !parentNode.classList.contains(prefix + "input-shell")) {
            createShell(element);
            element.addEventListener("change", handleValueChange, false);
            element.addEventListener("keyup", handleValueChange, false);
            if (spacing > 0) {
                element.addEventListener("focus", handleFocusEvent, false);
            }
        }
    }

    element[stringInputMask] = self;
}
