import { uicoreCustomEvent, prefix, DOC, offWindowLeft, createElement, isIE, isArray, each } from "./utilities";

export default function Slider(element, optionsInput) {
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    var isJson = false,
        jsonParams = element.dataset.slider;
    if (jsonParams) {
        optionsInput = JSON.parse(jsonParams);
        isJson = true;
    }
    var options = optionsInput || {};

    // User supplied
    options.animate = typeof options.animate == "boolean" ? options.animate : true;
    options.animationDuration = typeof options.animationDuration == "number" ? options.animationDuration : 300;
    options.ort = options.orientation ? (options.orientation === "vertical" ? 1 : 0) : 0;
    options.dir = options.direction ? (options.direction === "rtl" ? 1 : 0) : options.ort == 1 ? 1 : 0;
    options.singleStep = typeof options.step == "number" ? options.step : undefined;
    options.percision = typeof options.percision == "number" ? (options.percision > 2 ? 2 : options.percision) : 0;
    // User supplied but heeds more calculations at see validateOptions
    options.behaviour = options.behaviour ? options.behaviour : "tap";
    options.margin = typeof options.margin == "number" ? options.margin : 1;
    options.limit = typeof options.limit == "number" ? options.limit : 0;
    options.padding = options.padding ? options.padding : 0;
    options.tooltips = typeof options.tooltips == "boolean" ? options.tooltips : true;
    options.content = typeof options.content == "string" ? options.content : false;
    options.showRange = typeof options.showRange == "boolean" ? options.showRange : false;
    // Formats at see validateOptions
    options.format = typeof options.format == "object" ? options.format : undefined;
    options.ariaFormat = typeof options.ariaFormat == "object" ? options.ariaFormat : undefined;

    var stringSlider = "Slider",
        slider,
        container,
        spectrum,
        optEvents = {},
        events = {},
        connects,
        handles,
        handleNumbers,
        sliderBase,
        parsed,
        actions,
        supportsPassive = isIE ? false : true,
        locations = [],
        values = [],
        tooltips,
        activeHandlesCount = 0,
        minContainers = [],
        maxContainers = [],
        // For horizontal sliders in standard ltr documents,
        // make .dds__slider-origin overflow to the left so the document doesn't scroll.
        dirOffset = options.dir == 1 || options.ort == 1 ? 0 : 100,
        msPrefix = element.style.msTransform !== undefined,
        noPrefix = element.style.transform !== undefined,
        transformRule = noPrefix ? "transform" : msPrefix ? "msTransform" : "webkitTransform",
        // Default formatter uses numbers only and supplies percision/decimal places
        defaultFormatter = {
            to: function (value) {
                return value !== undefined && value.toFixed(options.percision);
            },
            from: Number,
        },
        // Validation
        validateMargin = function (entry) {
            if (options.margin === 0) {
                return;
            }

            options.margin = spectrum.getMargin(entry);

            if (!options.margin) {
                throw new Error("Slider 'margin' option is only supported on linear sliders.");
            }
        },
        validateOptions = function () {
            // Range always first initiates Spectrum
            if (options.range) {
                // Filter incorrect input.
                if (typeof options.range !== "object" || isArray(options.range)) {
                    throw new Error("Slider 'range' is not an object.");
                }

                // Update if only one handle
                if (options.range.min === undefined) {
                    options.range.min = 0;
                }

                // Catch missing end.
                if (options.range.max === undefined) {
                    throw new Error("Slider Missing 'max' in 'range'.");
                }

                // Catch equal start or end.
                if (options.range.min === options.range.max) {
                    throw new Error("Slider 'range' 'min' and 'max' cannot be equal.");
                }

                spectrum = new Spectrum(options.range, options.snap, options.singleStep);
            } else {
                throw new RangeError(
                    "There was a problem found with the range option values, please correct and try again!"
                );
            }
            // Start
            if (options.start) {
                var entry = asArray(options.start);

                // Validate input. Values aren't tested, as the public .val method
                // will always provide a valid location.
                if (!Array.isArray(entry) || !entry.length) {
                    throw new Error("Slider 'start' option is incorrect.");
                }

                // Store the number of handles.
                options.handles = entry.length;

                // When the slider is initialized, the .val method will
                // be called with the start options.
                options.start = entry;
            } else {
                if (options.range.min && options.range.max) {
                    options.start = [options.range.min, options.range.max];
                    options.handles = 2;
                } else {
                    options.start = options.range.max;
                    options.handles = 1;
                }
            }
            // Events
            if (options.behaviour) {
                // Make sure the input is a string.
                if (typeof options.behaviour !== "string") {
                    throw new Error("Slider 'behaviour' must be a string containing options.");
                }

                // Check if the string contains any keywords.
                // None are required.
                var tap = options.behaviour.indexOf("tap") >= 0;
                var drag = options.behaviour.indexOf("drag") >= 0;
                var fixed = options.behaviour.indexOf("fixed") >= 0;
                var snap = options.behaviour.indexOf("snap") >= 0;
                var hover = options.behaviour.indexOf("hover") >= 0;
                var unconstrained = options.behaviour.indexOf("unconstrained") >= 0;

                if (fixed) {
                    if (parsed.handles !== 2) {
                        throw new Error("Slider 'fixed' behaviour must be used with 2 handles");
                    }

                    // Use margin to enforce fixed state
                    validateMargin(options.start[1] - options.start[0]);
                }

                if (unconstrained && (parsed.margin || parsed.limit)) {
                    throw new Error("Slider 'unconstrained' behaviour cannot be used with margin or limit");
                }

                optEvents = {
                    tap: tap || snap,
                    drag: drag,
                    fixed: fixed,
                    snap: snap,
                    hover: hover,
                    unconstrained: unconstrained,
                };
            }
            // Margin
            if (optEvents.fixed == 0) {
                // at see validateBehaviour
                validateMargin(options.margin);
            }
            // Limit
            options.limit = spectrum.getMargin(options.limit);
            // Padding
            if (options.padding) {
                if (typeof options.padding == "number" && !Array.isArray(options.padding)) {
                    throw new Error("Slider 'padding' option must be numeric or array of exactly 2 numbers.");
                }

                if (
                    Array.isArray(options.padding) &&
                    !(
                        options.padding.length == 2 ||
                        typeof options.padding[0] != "number" ||
                        typeof options.padding[1] != "number"
                    )
                ) {
                    throw new Error("Slider 'padding' option must be numeric or array of exactly 2 numbers.");
                }

                if (options.padding === 0) {
                    return;
                }

                if (!Array.isArray(options.padding)) {
                    options.padding = [options.padding];
                }

                // 'getMargin' returns false for invalid values.
                options.padding = [spectrum.getMargin(options.padding[0]), spectrum.getMargin(options.padding[1])];

                if (options.padding[0] === false || options.padding[1] === false) {
                    throw new Error("Slider 'padding' option is only supported on linear sliders.");
                }

                if (options.padding[0] < 0 || options.padding[1] < 0) {
                    throw new Error("Slider 'padding' option must be a positive number(s).");
                }

                if (options.padding[0] + options.padding[1] > 100) {
                    throw new Error("Slider 'padding' option must not exceed 100% of the range.");
                }
            }
            // Tooltips
            if (options.tooltips) {
                var tooltips;
                if (options.tooltips === false) {
                    return;
                }

                if (options.tooltips === true) {
                    tooltips = [];

                    for (var i = 0; i < options.handles; i++) {
                        tooltips.push(true);
                    }
                } else {
                    tooltips = asArray(options.tooltips);

                    if (tooltips.length !== handles) {
                        throw new Error("Slider must pass a formatter for all handles.");
                    }

                    tooltips.forEach(function (formatter) {
                        if (
                            typeof formatter !== "boolean" &&
                            (typeof formatter !== "object" || typeof formatter.to !== "function")
                        ) {
                            throw new Error("Slider 'tooltips' must be passed a formatter or 'false'.");
                        }
                    });
                }
                options.tooltips = tooltips;
            }
            // Formats
            if (!options.format) {
                if (isJson) {
                    var inputs = JSON.parse(jsonParams);
                    if (inputs.format) {
                        var testJs;
                        if (inputs.format.indexOf("(") > -1) {
                            testJs = inputs.format.substring(0, inputs.format.indexOf("(")).trim();
                        } else {
                            testJs = inputs.format;
                        }
                        if (!window[testJs]) {
                            throw new Error(
                                "The supplied format function " +
                                    inputs.format +
                                    " could not be accessed, please verify and try again!"
                            );
                        } else {
                            options.format = window[testJs];
                        }
                    } else {
                        options.format = defaultFormatter;
                    }
                } else {
                    options.format = defaultFormatter;
                }
            }
            if (!options.ariaFormat) {
                if (isJson) {
                    inputs = JSON.parse(jsonParams);
                    if (inputs.ariaFormat) {
                        if (inputs.ariaFormat.indexOf("(") > -1) {
                            testJs = inputs.ariaFormat.substring(0, inputs.ariaFormat.indexOf("(")).trim();
                        } else {
                            testJs = inputs.ariaFormat;
                        }
                        if (!window[testJs]) {
                            throw new Error(
                                "The supplied format function " +
                                    inputs.ariaFormat +
                                    " could not be accessed, please verify and try again!"
                            );
                        } else {
                            options.ariaFormat = window[testJs];
                        }
                    } else {
                        options.ariaFormat = options.format;
                    }
                } else {
                    options.ariaFormat = options.format;
                }
            }
            if (options.format) {
                if (!options.format.to || !options.format.from) {
                    throw new Error(
                        "The supplied format function " +
                            options.format +
                            " is missing required method to or from, please verify and try again!"
                    );
                }
            }
            if (options.ariaFormat) {
                if (!options.ariaFormat.to || !options.ariaFormat.from) {
                    throw new Error(
                        "The supplied format function " +
                            options.format +
                            " is missing required method to or from, please verify and try again!"
                    );
                }
            }
        },
        // Event Handlers
        endEventHandler = function () {
            var data = get();
            if (!Array.isArray(data)) {
                data = asArray(data);
            }
            for (var d = 0; d < data.length; d++) {
                data[d] = typeof data[d] == "string" ? data[d] : options.format.to(data[d]);
            }
            var containers = null;
            if (handles.length == 1) {
                containers = maxContainers;
            } else {
                containers = minContainers;
            }

            for (var c = 0; containers.length > c; c++) {
                containers[c].innerHTML = data[0];
            }
            if (handles.length > 1) {
                for (var max = 0; maxContainers.length > max; max++) {
                    maxContainers[max].innerHTML = data[1];
                }
            }
            var details = {};
            if (data[1]) {
                details.min = data[0];
                details.max = data[1];
            } else {
                details.value = data[0];
            }
            uicoreCustomEvent("Slider", "SlideEnd", element, details);
        },
        focusEventHandler = function (e) {
            e["currentTarget"].classList.add(prefix + "slider-active");
        },
        blurEventHandler = function (e) {
            e["currentTarget"].classList.remove(prefix + "slider-active");
        },
        // Handles keydown on focused handles
        // Don't move the document when pressing arrow keys on focused handles
        eventKeydown = function (event, handleNumber) {
            if (isSliderDisabled() || isHandleDisabled(handleNumber)) {
                return false;
            }

            var horizontalKeys = ["Left", "Right"];
            var verticalKeys = ["Down", "Up"];
            var largeStepKeys = ["PageDown", "PageUp"];
            var edgeKeys = ["Home", "End"];

            if (options.dir && !options.ort) {
                // On an right-to-left slider, the left and right keys act inverted
                horizontalKeys.reverse();
            } else if (options.ort && !options.dir) {
                // On a top-to-bottom slider, the up and down keys act inverted
                verticalKeys.reverse();
                largeStepKeys.reverse();
            }

            // Strip "Arrow" for IE compatibility. https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
            var key = event.key.replace("Arrow", "");

            var isLargeDown = key === largeStepKeys[0];
            var isLargeUp = key === largeStepKeys[1];
            var isDown = key === verticalKeys[0] || key === horizontalKeys[0] || isLargeDown;
            var isUp = key === verticalKeys[1] || key === horizontalKeys[1] || isLargeUp;
            var isMin = key === edgeKeys[0];
            var isMax = key === edgeKeys[1];

            if (!isDown && !isUp && !isMin && !isMax) {
                return true;
            }

            event.preventDefault();

            var to;

            if (isUp || isDown) {
                var multiplier = 5;
                var direction = isDown ? 0 : 1;
                var steps = getNextStepsForHandle(handleNumber);
                var step = steps[direction];

                // At the edge of a slider, do nothing
                if (step === null) {
                    return false;
                }

                // No step set, use the default of 10% of the sub-range
                if (step === false) {
                    step = Spectrum.getDefaultStep(locations[handleNumber], isDown, 10);
                }

                if (isLargeUp || isLargeDown) {
                    step *= multiplier;
                }

                // Step over zero-length ranges (#948);
                step = Math.max(step, 0.0000001);

                // Decrement for down steps
                step = (isDown ? -1 : 1) * step;

                to = values[handleNumber] + step;
            } else if (isMax) {
                // End key
                to = options.spectrum.xVal[options.spectrum.xVal.length - 1];
            } else {
                // Home key
                to = options.spectrum.xVal[0];
            }

            setHandle(handleNumber, spectrum.toStepping(to), true, true);

            fireEvent("slide", handleNumber);
            fireEvent("update", handleNumber);
            fireEvent("change", handleNumber);
            fireEvent("set", handleNumber);

            return false;
        },
        getNextStepsForHandle = function (handleNumber) {
            var location = locations[handleNumber];
            var nearbySteps = spectrum.getNearbySteps(location);
            var value = values[handleNumber];
            var increment = nearbySteps.thisStep.step;
            var decrement = null;

            // If snapped, directly use defined step value
            if (options.snap) {
                return [
                    value - nearbySteps.stepBefore.startValue || null,
                    nearbySteps.stepAfter.startValue - value || null,
                ];
            }

            // If the next value in this step moves into the next step,
            // the increment is the start of the next step - the current value
            if (increment !== false) {
                if (value + increment > nearbySteps.stepAfter.startValue) {
                    increment = nearbySteps.stepAfter.startValue - value;
                }
            }

            // If the value is beyond the starting point
            if (value > nearbySteps.thisStep.startValue) {
                decrement = nearbySteps.thisStep.step;
            } else if (nearbySteps.stepBefore.step === false) {
                decrement = false;
            }

            // If a handle is at the start of a step, it always steps back into the previous step first
            else {
                decrement = value - nearbySteps.stepBefore.highestStep;
            }

            // Now, if at the slider edges, there is no in/decrement
            if (location === 100) {
                increment = null;
            } else if (location === 0) {
                decrement = null;
            }

            // As per #391, the comparison for the decrement step can have some rounding issues.
            var stepDecimals = spectrum.countStepDecimals();

            // Round per #391
            if (increment !== null && increment !== false) {
                increment = Number(increment.toFixed(stepDecimals));
            }

            if (decrement !== null && decrement !== false) {
                decrement = Number(decrement.toFixed(stepDecimals));
            }

            return [decrement, increment];
        },
        // Attach events to several slider parts.
        bindSliderEvents = function (behaviour) {
            // Attach the standard drag event to the handles.
            if (!behaviour.fixed) {
                handles.forEach(function (handle, index) {
                    // These events are only bound to the visual handle
                    // element, not the 'real' origin element.
                    attachEvent(actions.start, handle.children[0], eventStart, {
                        handleNumbers: [index],
                    });
                });
            }

            // Attach the tap event to the slider base.
            if (behaviour.tap) {
                attachEvent(actions.start, sliderBase, eventTap, {});
            }

            // Fire hover events
            if (behaviour.hover) {
                // attachEvent(actions.move, sliderBase, eventHover, {
                //     hover: true
                // });
            }

            // Make the range draggable.
            if (behaviour.drag) {
                connects.forEach(function (connect, index) {
                    if (connect === false || index === 0 || index === connects.length - 1) {
                        return;
                    }

                    var handleBefore = handles[index - 1];
                    var handleAfter = handles[index];
                    var eventHolders = [connect];

                    connect.classList.add(prefix + "slider-draggable");

                    // When the range is fixed, the entire range can
                    // be dragged by the handles. The handle in the first
                    // origin will propagate the start event upward,
                    // but it needs to be bound manually on the other.
                    if (behaviour.fixed) {
                        eventHolders.push(handleBefore.children[0]);
                        eventHolders.push(handleAfter.children[0]);
                    }

                    eventHolders.forEach(function (eventHolder) {
                        attachEvent(actions.start, eventHolder, eventStart, {
                            handles: [handleBefore, handleAfter],
                            handleNumbers: [index - 1, index],
                        });
                    });
                });
            }
        },
        // Attach an event to this slider, possibly including a namespace
        bindEvent = function (namespacedEvent, callback) {
            events[namespacedEvent] = events[namespacedEvent] || [];
            events[namespacedEvent].push(callback);

            // If the event bound is 'update,' fire it immediately for all handles.
            if (namespacedEvent.split(".")[0] === "update") {
                handles.forEach(function (a, index) {
                    fireEvent("update", index);
                });
            }
        },
        // Helpers
        addNodeTo = function (addTarget, className) {
            var div = createElement("div", {
                class: className ? className : "",
            });

            addTarget.appendChild(div);

            return div;
        },
        // Round a value to the closest 'to'.
        closest = function (value, to) {
            return Math.round(value / to) * to;
        },
        // Current position of an element relative to the document.
        offset = function (elem, orientation) {
            var rect = elem.getBoundingClientRect();
            var doc = elem.ownerDocument;
            var docElem = doc.documentElement;
            var pageOffset = getPageOffset(doc);

            // getBoundingClientRect contains left scroll in Chrome on Android.
            // I haven't found a feature detection that proves this. Worst case
            // scenario on mis-match: the 'tap' feature on horizontal sliders breaks.
            if (/webkit.*Chrome.*Mobile/i.test(navigator.userAgent)) {
                pageOffset.x = 0;
            }
            return orientation
                ? rect.top + pageOffset.y - docElem.clientTop
                : rect.left + pageOffset.x - docElem.clientLeft;
        },
        // Fire 'end' when a mouse or pen leaves the document.
        documentLeave = function (event, data) {
            if (event.type === "mouseout" && event.target.nodeName === "HTML" && event.relatedTarget === null) {
                eventEnd(event, data);
            }
        },
        // Handle movement on document for handle and range drag.
        eventMove = function (event, data) {
            // Check if we are moving up or down
            var movement = (options.dir ? -1 : 1) * (event.calcPoint - data.startCalcPoint);

            // Convert the movement into a percentage of the slider width/height
            var proposal = (movement * 100) / data.baseSize;

            moveHandles(movement > 0, proposal, data.locations, data.handleNumbers);
        },
        // Unbind move events on document, call callbacks.
        eventEnd = function (event, data) {
            // The handle is no longer active, so remove the class.
            if (data.handle) {
                data.handle.classList.remove(prefix + "slider-active");
                activeHandlesCount -= 1;
            }

            // Unbind the move and end events, which are added on 'start'.
            data.listeners.forEach(function (c) {
                DOC.removeEventListener(c[0], c[1]);
            });

            if (activeHandlesCount === 0) {
                // Remove dragging class.
                slider.classList.remove(prefix + "slider-drag");
                setZindex();

                // Remove cursor styles and text-selection events bound to the element.
                if (event.cursor) {
                    element.style.cursor = "";
                }
            }

            data.handleNumbers.forEach(function (handleNumber) {
                fireEvent("change", handleNumber);
                fireEvent("set", handleNumber);
                fireEvent("end", handleNumber);
            });
        },
        // Bind move events on document.
        eventStart = function (event, data) {
            // Ignore event if any handle is disabled
            if (data.handleNumbers.some(isHandleDisabled)) {
                return false;
            }

            var handle;

            if (data.handleNumbers.length === 1) {
                var handleOrigin = handles[data.handleNumbers[0]];

                handle = handleOrigin.children[0];
                activeHandlesCount += 1;

                // Mark the handle as 'active' so it can be styled.
                handle.classList.add(prefix + "slider-active");
            }

            // A drag should never propagate up to the 'tap' event.
            event.stopPropagation();

            // Record the event listeners.
            var listeners = [];

            // Attach the move and end events.
            var moveEvent = attachEvent(actions.move, DOC, eventMove, {
                // The event target has changed so we need to propagate the original one so that we keep
                // relying on it to extract target touches.
                target: event.target,
                handle: handle,
                listeners: listeners,
                startCalcPoint: event.calcPoint,
                baseSize: baseSize(),
                pageOffset: event.pageOffset,
                handleNumbers: data.handleNumbers,
                buttonsProperty: event.buttons,
                locations: locations.slice(),
            });

            var endEvent = attachEvent(actions.end, DOC, eventEnd, {
                target: event.target,
                handle: handle,
                listeners: listeners,
                doNotReject: true,
                handleNumbers: data.handleNumbers,
            });

            var outEvent = attachEvent("mouseout", DOC, documentLeave, {
                target: event.target,
                handle: handle,
                listeners: listeners,
                doNotReject: true,
                handleNumbers: data.handleNumbers,
            });

            // We want to make sure we pushed the listeners in the listener list rather than creating
            // a new one as it has already been passed to the event handlers.
            listeners.push.apply(listeners, moveEvent.concat(endEvent, outEvent));

            // Text selection isn't an issue on touch devices,
            // so adding cursor styles can be skipped.
            if (event.cursor) {
                // Prevent the 'I' cursor and extend the range-drag cursor.
                element.style.cursor = getComputedStyle(event.target).cursor;

                // Mark the target with a dragging state.
                if (handles.length > 1) {
                    slider.classList.add(prefix + "slider-drag");
                }
            }

            data.handleNumbers.forEach(function (handleNumber) {
                fireEvent("start", handleNumber);
            });
        },
        // Move closest handle to tapped location.
        eventTap = function (event) {
            // The tap event shouldn't propagate up
            event.stopPropagation();

            var proposal = calcPointToPercentage(event.calcPoint);
            var handleNumber = getClosestHandle(proposal);

            // Tackle the case that all handles are 'disabled'.
            if (handleNumber === false) {
                return false;
            }

            // Flag the slider as it is now in a transitional state.
            // Transition takes a configurable amount of ms (default 300). Re-enable the slider after that.
            if (!events.snap) {
                addClassFor(slider, prefix + "slider-tap", options.animationDuration);
            }

            setHandle(handleNumber, proposal, true, true);

            setZindex();

            fireEvent("slide", handleNumber, true);
            fireEvent("update", handleNumber, true);
            fireEvent("change", handleNumber, true);
            fireEvent("set", handleNumber, true);

            if (events.snap) {
                eventStart(event, { handleNumbers: [handleNumber] });
            }
        },
        addTooltip = function (handle, handleNumber) {
            if (!options.tooltips[handleNumber]) {
                return false;
            }

            return addNodeTo(handle.firstChild, prefix + "slider-tooltip");
        },
        isSliderDisabled = function () {
            return slider.hasAttribute("disabled");
        },
        // Disable the slider dragging if any handle is disabled
        isHandleDisabled = function (handleNumber) {
            var handleOrigin = handles[handleNumber];
            return handleOrigin.hasAttribute("disabled");
        },
        // Append a origin to the base
        addOrigin = function (handleNumber) {
            var origin = addNodeTo(sliderBase, prefix + "slider-origin");
            var handle = addNodeTo(origin, prefix + "slider-handle");

            addNodeTo(handle, prefix + "slider-touch-area");

            handle.setAttribute("data-handle", handleNumber);

            // keyboard support
            handle.setAttribute("tabindex", "0");
            handle.addEventListener("keydown", function (event) {
                return eventKeydown(event, handleNumber);
            });

            handle.setAttribute("role", "slider");
            handle.setAttribute("aria-orientation", options.ort ? "vertical" : "horizontal");

            if (options.handles == 1) {
                handle.classList.add(prefix + "slider-handle-upper");
            } else if (handleNumber === 0) {
                handle.classList.add(prefix + "slider-handle-lower");
            } else {
                handle.classList.add(prefix + "slider-handle-upper");
            }

            return origin;
        },
        // nsert nodes for connect elements
        addConnect = function (base, add) {
            if (!add) {
                return false;
            }

            return addNodeTo(base, prefix + "slider-connect");
        },
        addLabels = function () {
            var min, max, minLabel, maxLabel;
            var minValue = options.format.to(options.range.min);
            var maxValue = options.format.to(options.range.max);
            if (handles.length == 1) {
                max = createElement("div", {
                    class: prefix + "slider-max",
                });
                maxLabel = addNodeTo(max);
                maxLabel.innerHTML = maxValue;
                maxContainers.push(maxLabel);
            } else {
                min = createElement("div", {
                    class: prefix + "slider-min",
                });
                minLabel = addNodeTo(min);
                minLabel.innerHTML = minValue;
                minContainers.push(minLabel);
                max = createElement("div", {
                    class: prefix + "slider-max",
                });
                maxLabel = addNodeTo(max);
                maxLabel.innerHTML = maxValue;
                maxContainers.push(maxLabel);
            }
            if (options.ort === 0) {
                if (min) {
                    container.insertBefore(min, container.childNodes[0]);
                }
                container.appendChild(max);
            } else {
                if (min) {
                    container.appendChild(min);
                }
                container.insertBefore(max, container.childNodes[0]);
            }
        },
        addContent = function () {
            var label = createElement("div", {
                class:
                    prefix +
                    "text-no-wrap " +
                    prefix +
                    "mb-3" +
                    (options.ort == 1
                        ? " " + prefix + "d-flex " + prefix + "flex-column " + prefix + "align-items-center"
                        : ""),
            });
            var text = DOC.createTextNode(options.content);
            label.appendChild(text);
            element.insertBefore(label, element.firstChild);
            if (options.showRange) {
                var span = createElement("span", {
                    html:
                        " ( " +
                        options.format.to(options.range.min) +
                        " - " +
                        options.format.to(options.range.max) +
                        " )",
                });
                label.appendChild(span);
            }
        },
        addContainer = function () {
            container = element.querySelector("." + prefix + "slider-container");

            // if vertical add class to parent
            if (options.ort == 1) {
                container.classList.add(prefix + "slider-container-vertical");
            }

            // update wrapper to singe with one handle
            if (options.handles == 1) {
                var wrapper = container.querySelector("." + prefix + "slider-wrapper");
                wrapper.classList.remove(prefix + "slider-wrapper");
                wrapper.classList.add(prefix + "slider-wrapper-single");
            }
        },
        addElements = function () {
            var connectBase = addNodeTo(sliderBase, prefix + "slider-connects");

            handles = [];
            connects = [];
            handleNumbers = [];
            var optConnects;

            if (options.handles === 1) {
                optConnects = [true, false];
            } else {
                optConnects = [false, options.range, false];
            }
            connects.push(addConnect(connectBase, optConnects[0]));

            for (var i = 0; i < options.handles; i++) {
                // Keep a list of all added handles.
                handles.push(addOrigin(i));
                handleNumbers[i] = i;
                connects.push(addConnect(connectBase, optConnects[i + 1]));
            }
        },
        // Initialize a single slider.
        addSlider = function () {
            if (options.dir === 0) {
                slider.classList.add(prefix + "slider-ltr");
            } else {
                slider.classList.add(prefix + "slider-rtl");
            }

            if (options.ort === 0) {
                slider.classList.add(prefix + "slider-horizontal");
            } else {
                slider.classList.add(prefix + "slider-vertical");
            }

            var base = addNodeTo(slider, prefix + "slider-base");
            base.setAttribute("tabindex", "0");
            base.setAttribute(
                "aria-label",
                (options.content ? options.content : "Slider") +
                    " " +
                    options.format.to(options.range.min) +
                    " to " +
                    options.format.to(options.range.max)
            );

            return base;
        },
        valueSet = function (input, fireSetEvent) {
            var values = asArray(input);
            var isInit = locations[0] === undefined;

            // Event fires by default
            fireSetEvent = fireSetEvent === undefined ? true : !!fireSetEvent;

            // Animation is optional.
            // Make sure the initial values were set before using animated placement.
            if (options.animate && !isInit) {
                addClassFor(slider, prefix + "slider-tap", options.animationDuration);
            }

            // First pass, without lookAhead but with lookBackward. Values are set from left to right.
            handleNumbers.forEach(function (handleNumber) {
                setHandle(handleNumber, resolveToValue(values[handleNumber], handleNumber), true, false);
            });

            // Second pass. Now that all base values are set, apply constraints
            handleNumbers.forEach(function (handleNumber) {
                setHandle(handleNumber, locations[handleNumber], true, true);
            });

            setZindex();

            handleNumbers.forEach(function (handleNumber) {
                fireEvent("update", handleNumber);

                // Fire the event only for handles that received a new value, as per #579
                if (values[handleNumber] !== null && fireSetEvent) {
                    fireEvent("set", handleNumber);
                }
            });
        },
        asArray = function (a) {
            return Array.isArray(a) ? a : [a];
        },
        addClassFor = function (element, className, duration) {
            if (duration > 0) {
                element.classList.add(className);
                setTimeout(function () {
                    element.classList.remove(className);
                }, duration);
            }
        },
        // Counts decimals
        countDecimals = function (numStr) {
            numStr = String(numStr);
            var pieces = numStr.split(".");
            return pieces.length > 1 ? pieces[1].length : 0;
        },
        // Limits a value to 0 - 100
        limit = function (a) {
            return Math.max(Math.min(a, 100), 0);
        },
        // Moves handle(s) by a percentage
        // (bool, % to move, [% where handle started, ...], [index in handles, ...])
        moveHandles = function (upward, proposal, locations, handleNumbers) {
            var proposals = locations.slice();

            var b = [!upward, upward];
            var f = [upward, !upward];

            // Copy handleNumbers so we don't change the dataset
            handleNumbers = handleNumbers.slice();

            // Check to see which handle is 'leading'.
            // If that one can't move the second can't either.
            if (upward) {
                handleNumbers.reverse();
            }

            // Step 1: get the maximum percentage that any of the handles can move
            if (handleNumbers.length > 1) {
                handleNumbers.forEach(function (handleNumber, o) {
                    var to = checkHandlePosition(
                        proposals,
                        handleNumber,
                        proposals[handleNumber] + proposal,
                        b[o],
                        f[o],
                        false
                    );

                    // Stop if one of the handles can't move.
                    if (to === false) {
                        proposal = 0;
                    } else {
                        proposal = to - proposals[handleNumber];
                        proposals[handleNumber] = to;
                    }
                });
            }

            // If using one handle, check backward AND forward
            else {
                b = f = [true];
            }

            var state = false;

            // Step 2: Try to set the handles with the found percentage
            handleNumbers.forEach(function (handleNumber, o) {
                state = setHandle(handleNumber, locations[handleNumber] + proposal, b[o], f[o]) || state;
            });

            // Step 3: If a handle moved, fire events
            if (state) {
                handleNumbers.forEach(function (handleNumber) {
                    fireEvent("update", handleNumber);
                    fireEvent("slide", handleNumber);
                });
            }
        },
        // Takes a base value and an offset. This offset is used for the connect bar size.
        // In the initial design for this feature, the origin element was 1% wide.
        // Unfortunately, a rounding bug in Chrome makes it impossible to implement this feature
        // in this manner: https://bugs.chromium.org/p/chromium/issues/detail?id=798223
        transformDirection = function (a, b) {
            return options.dir ? 100 - a - b : a;
        },
        // Updates locations and values, updates visual state
        updateHandlePosition = function (handleNumber, to) {
            // Update locations.
            locations[handleNumber] = to;

            // Convert the value to the slider stepping/range.
            values[handleNumber] = spectrum.fromStepping(to);

            var rule = "translate(" + inRuleOrder(transformDirection(to, 0) - dirOffset + "%", "0") + ")";
            handles[handleNumber].style[transformRule] = rule;

            updateConnect(handleNumber);
            updateConnect(handleNumber + 1);
        },
        // Test suggested values and apply margin, step.
        setHandle = function (handleNumber, to, lookBackward, lookForward) {
            to = checkHandlePosition(locations, handleNumber, to, lookBackward, lookForward, false);

            if (to === false) {
                return false;
            }

            updateHandlePosition(handleNumber, to);

            return true;
        },
        // Updates style attribute for connect nodes
        updateConnect = function (index) {
            // Skip connects set to false
            if (!connects[index]) {
                return;
            }

            var l = 0;
            var h = 100;

            if (index !== 0) {
                l = locations[index - 1];
            }

            if (index !== connects.length - 1) {
                h = locations[index];
            }

            // We use two rules:
            // 'translate' to change the left/top offset;
            // 'scale' to change the width of the element;
            // As the element has a width of 100%, a translation of 100% is equal to 100% of the parent (.noUi-base)
            var connectWidth = h - l;
            var translateRule = "translate(" + inRuleOrder(transformDirection(l, connectWidth) + "%", "0") + ")";
            var scaleRule = "scale(" + inRuleOrder(connectWidth / 100, "1") + ")";

            connects[index].style[transformRule] = translateRule + " " + scaleRule;
        },
        setZindex = function () {
            handleNumbers.forEach(function (handleNumber) {
                var dir = locations[handleNumber] > 50 ? -1 : 1;
                var zIndex = 3 + (handles.length + dir * handleNumber);
                handles[handleNumber].style.zIndex = zIndex;
            });
        },
        // Undo attachment of event
        removeEvent = function (namespacedEvent) {
            var event = namespacedEvent && namespacedEvent.split(".")[0];
            var namespace = event && namespacedEvent.substring(event.length);

            Object.keys(events).forEach(function (bind) {
                var tEvent = bind.split(".")[0];
                var tNamespace = bind.substring(tEvent.length);

                if ((!event || event === tEvent) && (!namespace || namespace === tNamespace)) {
                    delete events[bind];
                }
            });
        },
        // External event handling
        fireEvent = function (eventName, handleNumber, tap) {
            Object.keys(events).forEach(function (targetEvent) {
                var eventType = targetEvent.split(".")[0];

                if (eventName === eventType) {
                    events[targetEvent].forEach(function (callback) {
                        callback.call(
                            // Use the slider public API as the scope ('this')
                            self,
                            // Return values as array, so arg_1[arg_2] is always valid.
                            values.map(options.format.to),
                            // Handle index, 0 or 1
                            handleNumber,
                            // Un-formatted slider values
                            values.slice(),
                            // Event is fired by tap, true or false
                            tap || false,
                            // Left offset of the handle, in relation to the slider
                            locations.slice()
                        );
                    });
                }
            });
        },
        // Split out the handle positioning logic so the Move event can use it, too
        checkHandlePosition = function (reference, handleNumber, to, lookBackward, lookForward, getValue) {
            // For sliders with multiple handles, limit movement to the other handle.
            // Apply the margin option by adding it to the handle positions.
            if (handles.length > 1 && !optEvents.unconstrained) {
                if (lookBackward && handleNumber > 0) {
                    to = Math.max(to, reference[handleNumber - 1] + options.margin);
                }

                if (lookForward && handleNumber < handles.length - 1) {
                    to = Math.min(to, reference[handleNumber + 1] - options.margin);
                }
            }

            // The limit option has the opposite effect, limiting handles to a
            // maximum distance from another. Limit must be > 0, as otherwise
            // handles would be unmovable.
            if (handles.length > 1 && options.limit) {
                if (lookBackward && handleNumber > 0) {
                    to = Math.min(to, reference[handleNumber - 1] + options.limit);
                }

                if (lookForward && handleNumber < handles.length - 1) {
                    to = Math.max(to, reference[handleNumber + 1] - options.limit);
                }
            }

            // The padding option keeps the handles a certain distance from the
            // edges of the slider. Padding must be > 0.
            if (options.padding) {
                if (handleNumber === 0) {
                    to = Math.max(to, options.padding[0]);
                }

                if (handleNumber === handles.length - 1) {
                    to = Math.min(to, 100 - options.padding[1]);
                }
            }

            to = spectrum.getStep(to);

            // Limit percentage to the 0 - 100 range
            to = limit(to);

            // Return false if handle can't move
            if (to === reference[handleNumber] && !getValue) {
                return false;
            }

            return to;
        },
        // Uses slider orientation to create CSS rules. a = base value;
        inRuleOrder = function (v, a) {
            var o = options.ort;
            return (o ? a : v) + ", " + (o ? v : a);
        },
        // Parses value passed to .set method. Returns current value if not parse-able.
        resolveToValue = function (to, handleNumber) {
            // Setting with null indicates an 'ignore'.
            // Inputting 'false' is invalid.
            if (to === null || to === false || to === undefined) {
                return locations[handleNumber];
            }

            // If a formatted number was passed, attempt to decode it.
            if (typeof to === "number") {
                to = String(to);
            }

            to = options.format.from(to);
            to = spectrum.toStepping(to);

            // If parsing the number failed, use the current value.
            if (to === false || isNaN(to)) {
                return locations[handleNumber];
            }

            return to;
        },
        toStepping = function (xVal, xPct, value) {
            if (value >= xVal.slice(-1)[0]) {
                return 100;
            }

            var j = getJ(value, xVal);
            var va = xVal[j - 1];
            var vb = xVal[j];
            var pa = xPct[j - 1];
            var pb = xPct[j];

            return pa + toPercentage([va, vb], value) / subRangeRatio(pa, pb);
        },
        // (value) Input a percentage, find where it is on the specified range.
        fromStepping = function (xVal, xPct, value) {
            // There is no range group that fits 100
            if (value >= 100) {
                return xVal.slice(-1)[0];
            }

            var j = getJ(value, xPct);
            var va = xVal[j - 1];
            var vb = xVal[j];
            var pa = xPct[j - 1];
            var pb = xPct[j];

            return isPercentage([va, vb], (value - pa) * subRangeRatio(pa, pb));
        },
        subRangeRatio = function (pa, pb) {
            return 100 / (pb - pa);
        },
        // (percentage) How many percent is this value of this range?
        fromPercentage = function (range, value) {
            return (value * 100) / (range[1] - range[0]);
        },
        // (percentage) Where is this value on this range?
        toPercentage = function (range, value) {
            return fromPercentage(range, range[0] < 0 ? value + Math.abs(range[0]) : value - range[0]);
        },
        // (percentage) Get the step that applies at a certain value.
        getStep = function (xPct, xSteps, snap, value) {
            if (value === 100) {
                return value;
            }

            var j = getJ(value, xPct);
            var a = xPct[j - 1];
            var b = xPct[j];

            // If 'snap' is set, steps are used as fixed points on the slider.
            if (snap) {
                // Find the closest position, a or b.
                if (value - a > (b - a) / 2) {
                    return b;
                }

                return a;
            }

            if (!xSteps[j - 1]) {
                return value;
            }

            return xPct[j - 1] + closest(value - xPct[j - 1], xSteps[j - 1]);
        },
        handleEntryPoint = function (index, value, that) {
            var percentage;

            // Wrap numerical input in an array.
            if (typeof value === "number") {
                value = [value];
            }

            // Reject any invalid input, by testing whether value is an array.
            if (!isArray(value)) {
                throw new Error("Slider 'range' contains invalid value.");
            }

            // Covert min/max syntax to 0 and 100.
            if (index === "min") {
                percentage = 0;
            } else if (index === "max") {
                percentage = 100;
            } else {
                percentage = parseFloat(index);
            }

            // Check for correct input.
            if (!isNumeric(percentage) || !isNumeric(value[0])) {
                throw new Error("Slider 'range' value isn't numeric.");
            }

            // Store values.
            that.xPct.push(percentage);
            that.xVal.push(value[0]);

            // NaN will evaluate to false too, but to keep
            // logging clear, set step explicitly. Make sure
            // not to override the 'step' setting with false.
            if (!percentage) {
                if (!isNaN(value[1])) {
                    that.xSteps[0] = value[1];
                }
            } else {
                that.xSteps.push(isNaN(value[1]) ? false : value[1]);
            }

            that.xHighestCompleteStep.push(0);
        },
        handleStepPoint = function (i, n, that) {
            // Ignore 'false' stepping.
            if (!n) {
                return;
            }

            // Step over zero-length ranges (#948);
            if (that.xVal[i] === that.xVal[i + 1]) {
                that.xSteps[i] = that.xHighestCompleteStep[i] = that.xVal[i];

                return;
            }

            // Factor to range ratio
            that.xSteps[i] =
                fromPercentage([that.xVal[i], that.xVal[i + 1]], n) / subRangeRatio(that.xPct[i], that.xPct[i + 1]);

            var totalSteps = (that.xVal[i + 1] - that.xVal[i]) / that.xNumSteps[i];
            var highestStep = Math.ceil(Number(totalSteps.toFixed(3)) - 1);
            var step = that.xVal[i] + that.xNumSteps[i] * highestStep;

            that.xHighestCompleteStep[i] = step;
        },
        // Checks whether a value is numerical.
        isNumeric = function (a) {
            return typeof a === "number" && !isNaN(a) && isFinite(a);
        },
        // (value) How much is this percentage on this range?
        isPercentage = function (range, value) {
            return (value * (range[1] - range[0])) / 100 + range[0];
        },
        getJ = function (value, arr) {
            var j = 1;

            while (value >= arr[j]) {
                j += 1;
            }

            return j;
        },
        // Shorthand for base dimensions.
        baseSize = function () {
            var rect = sliderBase.getBoundingClientRect();
            var alt = "offset" + ["Width", "Height"][options.ort];
            return options.ort === 0 ? rect.width || sliderBase[alt] : rect.height || sliderBase[alt];
        },
        // Handler for attaching events trough a proxy.
        attachEvent = function (events, element, callback, data) {
            // This function can be used to 'filter' events to the slider.
            // element is a node, not a nodeList

            var method = function (e) {
                e = fixEvent(e, data.pageOffset, data.target || element);

                // fixEvent returns false if this event has a different target
                // when handling (multi-) touch events;
                if (!e) {
                    return false;
                }

                // doNotReject is passed by all end events to make sure released touches
                // are not rejected, leaving the slider "stuck" to the cursor;
                if (isSliderDisabled() && !data.doNotReject) {
                    return false;
                }

                // Stop if an active 'tap' transition is taking place.
                if (slider.classList.contains(prefix + "slider-tap") && !data.doNotReject) {
                    return false;
                }

                // Ignore right or middle clicks on start #454
                if (events === actions.start && e.buttons !== undefined && e.buttons > 1) {
                    return false;
                }

                // Ignore right or middle clicks on start #454
                if (data.hover && e.buttons) {
                    return false;
                }

                // 'supportsPassive' is only true if a browser also supports touch-action: none in CSS.
                // iOS safari does not, so it doesn't get to benefit from passive scrolling. iOS does support
                // touch-action: manipulation, but that allows panning, which breaks
                // sliders after zooming/on non-responsive pages.
                // See: https://bugs.webkit.org/show_bug.cgi?id=133112
                if (!supportsPassive) {
                    e.preventDefault();
                }

                e.calcPoint = e.points[options.ort];

                // Call the event handler with the event [ and additional data ].
                callback(e, data);
            };

            var methods = [];

            // Bind a closure on the target for every event type.
            events.split(" ").forEach(function (eventName) {
                element.addEventListener(eventName, method, supportsPassive ? { passive: true } : false);
                methods.push([eventName, method]);
            });

            return methods;
        },
        // Provide a clean event with standardized offset values.
        fixEvent = function (e, pageOffset, eventTarget) {
            // Filter the event to register the type, which can be
            // touch, mouse or pointer. Offset changes need to be
            // made on an event specific basis.
            var touch = e.type.indexOf("touch") === 0;
            var mouse = e.type.indexOf("mouse") === 0;
            var pointer = e.type.indexOf("pointer") === 0;

            var x;
            var y;

            // The only thing one handle should be concerned about is the touches that originated on top of it.
            if (touch) {
                // Returns true if a touch originated on the target.
                var isTouchOnTarget = function (checkTouch) {
                    return checkTouch.target === eventTarget || eventTarget.contains(checkTouch.target);
                };

                // In the case of touchstart events, we need to make sure there is still no more than one
                // touch on the target so we look amongst all touches.
                if (e.type === "touchstart") {
                    var targetTouches = Array.prototype.filter.call(e.touches, isTouchOnTarget);

                    // Do not support more than one touch per handle.
                    if (targetTouches.length > 1) {
                        return false;
                    }

                    x = targetTouches[0].pageX;
                    y = targetTouches[0].pageY;
                } else {
                    // In the other cases, find on changedTouches is enough.
                    var targetTouch = Array.prototype.find.call(e.changedTouches, isTouchOnTarget);

                    // Cancel if the target touch has not moved.
                    if (!targetTouch) {
                        return false;
                    }

                    x = targetTouch.pageX;
                    y = targetTouch.pageY;
                }
            }

            pageOffset = pageOffset || getPageOffset(DOC);

            if (mouse || pointer) {
                x = e.clientX + pageOffset.x;
                y = e.clientY + pageOffset.y;
            }

            e.pageOffset = pageOffset;
            e.points = [x, y];
            e.cursor = mouse || pointer; // Fix #435

            return e;
        },
        // Translate a coordinate in the document to a percentage on the slider
        calcPointToPercentage = function (calcPoint) {
            var location = calcPoint - offset(sliderBase, options.ort);
            var proposal = (location * 100) / baseSize();

            // Clamp proposal between 0% and 100%
            proposal = limit(proposal);

            return options.dir ? 100 - proposal : proposal;
        },
        // Find handle closest to a certain percentage on the slider
        getClosestHandle = function (proposal) {
            var closest = 100;
            var handleNumber = false;

            handles.forEach(function (handle, index) {
                // Disabled handles are ignored
                if (isHandleDisabled(index)) {
                    return;
                }

                var pos = Math.abs(locations[index] - proposal);

                if (pos < closest || (pos === 100 && closest === 100)) {
                    handleNumber = index;
                    closest = pos;
                }
            });

            return handleNumber;
        },
        removeTooltips = function () {
            if (tooltips) {
                removeEvent("update.tooltips");
                tooltips.forEach(function (tooltip) {
                    if (tooltip) {
                        removeElement(tooltip);
                    }
                });
                tooltips = null;
            }
        },
        // The tooltips option is a shorthand for using the 'update' event.
        setTooltips = function () {
            removeTooltips();

            // Tooltips are added with options.tooltips in original order.
            tooltips = handles.map(addTooltip);

            bindEvent("update.tooltips", function (values, handleNumber, unencoded) {
                if (!tooltips[handleNumber]) {
                    return;
                }

                var formattedValue = values[handleNumber];

                if (options.tooltips[handleNumber] !== true) {
                    formattedValue = options.tooltips[handleNumber].to(unencoded[handleNumber]);
                }

                tooltips[handleNumber].innerHTML = formattedValue;
            });

            // Fix for vertical sliders, if tooltip flows off the window move it the right side.
            if (
                options.ort == 1 &&
                offWindowLeft(
                    handles[0].querySelector("." + prefix + "slider-tooltip"),
                    handles[0].querySelector("div." + prefix + "slider-handle")
                )
            ) {
                each(handles, function (handle) {
                    handle.querySelector("." + prefix + "slider-tooltip").style.right = "auto";
                    handle.querySelector("." + prefix + "slider-tooltip").style.left = "120%";
                });
            }
        },
        aria = function () {
            bindEvent("update", function (values, handleNumber, unencoded, tap, positions) {
                // Update Aria Values for all handles, as a change in one changes min and max values for the next.
                handleNumbers.forEach(function (index) {
                    var handle = handles[index];

                    var min = checkHandlePosition(locations, index, 0, true, true, true);
                    var max = checkHandlePosition(locations, index, 100, true, true, true);

                    var now = positions[index];

                    // Formatted value for display
                    var text = options.ariaFormat.to(unencoded[index]);

                    // Map to slider range values
                    min = spectrum.fromStepping(min).toFixed(options.percision);
                    max = spectrum.fromStepping(max).toFixed(options.percision);
                    now = spectrum.fromStepping(now).toFixed(options.percision);

                    handle.children[0].setAttribute("aria-valuemin", min);
                    handle.children[0].setAttribute("aria-valuemax", max);
                    handle.children[0].setAttribute("aria-valuenow", now);
                    handle.children[0].setAttribute("aria-valuetext", text);
                });
            });
        },
        // Get the slider value.
        get = function () {
            var v = values.map(options.format.to);

            // If only one handle is used, return a single value.
            if (v.length === 1) {
                return values[0];
            }

            return v;
        },
        removeElement = function (el) {
            el.parentElement.removeChild(el);
        },
        getPageOffset = function (doc) {
            var supportPageOffset = window.pageXOffset !== undefined;
            var isCSS1Compat = (doc.compatMode || "") === "CSS1Compat";
            var x = supportPageOffset
                ? window.pageXOffset
                : isCSS1Compat
                ? doc.documentElement.scrollLeft
                : doc.body.scrollLeft;
            var y = supportPageOffset
                ? window.pageYOffset
                : isCSS1Compat
                ? doc.documentElement.scrollTop
                : doc.body.scrollTop;

            return {
                x: x,
                y: y,
            };
        },
        // we provide a function to compute constants instead
        // of accessing window.* as soon as the module needs it
        // so that we do not compute anything if not needed
        getActions = function () {
            // Determine the events to bind. IE11 implements pointerEvents without
            // a prefix, which breaks compatibility with the IE10 implementation.
            return window.navigator.pointerEnabled
                ? {
                      start: "pointerdown",
                      move: "pointermove",
                      end: "pointerup",
                  }
                : window.navigator.msPointerEnabled
                ? {
                      start: "MSPointerDown",
                      move: "MSPointerMove",
                      end: "MSPointerUp",
                  }
                : {
                      start: "mousedown touchstart",
                      move: "mousemove touchmove",
                      end: "mouseup touchend",
                  };
        };

    function Spectrum(entry, snap, singleStep) {
        this.xPct = [];
        this.xVal = [];
        this.xSteps = [singleStep || false];
        this.xNumSteps = [false];
        this.xHighestCompleteStep = [];

        this.snap = snap;

        var index;
        var ordered = []; // [0, 'min'], [1, '50%'], [2, 'max']

        // Map the object keys to an array.
        for (index in entry) {
            if (Object.prototype.hasOwnProperty.call(entry, index)) {
                ordered.push([entry[index], index]);
            }
        }

        // Sort all entries by value (numeric sort).
        if (ordered.length && typeof ordered[0][0] === "object") {
            ordered.sort(function (a, b) {
                return a[0][0] - b[0][0];
            });
        } else {
            ordered.sort(function (a, b) {
                return a[0] - b[0];
            });
        }

        // Convert all entries to subranges.
        for (index = 0; index < ordered.length; index++) {
            handleEntryPoint(ordered[index][1], ordered[index][0], this);
        }

        // Store the actual step values.
        // xSteps is sorted in the same order as xPct and xVal.
        this.xNumSteps = this.xSteps.slice(0);

        // Convert all numeric steps to the percentage of the subrange they represent.
        for (index = 0; index < this.xNumSteps.length; index++) {
            handleStepPoint(index, this.xNumSteps[index], this);
        }
    }

    Spectrum.prototype.getMargin = function (value) {
        var step = this.xNumSteps[0];

        if (step && (value / step) % 1 !== 0) {
            throw new Error("Slider 'limit', 'margin' and 'padding' must be divisible by step.");
        }

        return this.xPct.length === 2 ? fromPercentage(this.xVal, value) : false;
    };

    Spectrum.prototype.toStepping = function (value) {
        value = toStepping(this.xVal, this.xPct, value);

        return value;
    };

    Spectrum.prototype.fromStepping = function (value) {
        return fromStepping(this.xVal, this.xPct, value);
    };

    Spectrum.prototype.getStep = function (value) {
        value = getStep(this.xPct, this.xSteps, this.snap, value);

        return value;
    };

    Spectrum.prototype.getDefaultStep = function (value, isDown, size) {
        var j = getJ(value, this.xPct);

        // When at the top or stepping down, look at the previous sub-range
        if (value === 100 || (isDown && value === this.xPct[j - 1])) {
            j = Math.max(j - 1, 1);
        }

        return (this.xVal[j] - this.xVal[j - 1]) / size;
    };

    Spectrum.prototype.getNearbySteps = function (value) {
        var j = getJ(value, this.xPct);

        return {
            stepBefore: {
                startValue: this.xVal[j - 2],
                step: this.xNumSteps[j - 2],
                highestStep: this.xHighestCompleteStep[j - 2],
            },
            thisStep: {
                startValue: this.xVal[j - 1],
                step: this.xNumSteps[j - 1],
                highestStep: this.xHighestCompleteStep[j - 1],
            },
            stepAfter: {
                startValue: this.xVal[j],
                step: this.xNumSteps[j],
                highestStep: this.xHighestCompleteStep[j],
            },
        };
    };

    Spectrum.prototype.countStepDecimals = function () {
        var stepDecimals = this.xNumSteps.map(countDecimals);
        return Math.max.apply(null, stepDecimals);
    };

    // Outside testing
    Spectrum.prototype.convert = function (value) {
        return this.getStep(this.toStepping(value));
    };

    // init
    if (!(stringSlider in element)) {
        validateOptions(options);

        if (options.ort == 1) {
            element.classList.add(prefix + "align-items-center");
        }
        if (options.content) {
            addContent();
        }

        addContainer();

        actions = getActions();

        slider = element.querySelector("." + prefix + "slider-target");
        sliderBase = addSlider();
        addElements();

        // Attach user events.
        bindSliderEvents(optEvents);

        // Use the public value method to set the start values.
        valueSet(options.start);

        if (options.tooltips) {
            setTooltips();
        }

        aria();

        bindEvent("end", endEventHandler);

        addLabels();

        for (var i = 0; i < handles.length; i++) {
            handles[i].firstChild.addEventListener("focus", focusEventHandler, false);
            handles[i].firstChild.addEventListener("blur", blurEventHandler, false);
            handles[i].addEventListener("keyup", endEventHandler, false);
        }
        element.addEventListener("click", endEventHandler, false);
    }

    element[stringSlider] = self;
}
