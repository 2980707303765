import { each, isArray, createElement, getText } from "../utilities.js";

/**
 * Rows API
 * @param {Object} instance DataTable instance
 * @param {Array} rows
 */
export default function Rows(dt) {
    /**
     * Build a new row
     * @param  {Array} row
     * @return {HTMLElement}
     */
    this.build = function (row) {
        var td,
            tr = createElement("tr");

        var headings = dt.headings;

        if (!headings.length) {
            headings = row.map(function () {
                return "";
            });
        }

        each(row, function (tdx) {
            if (tdx.indexOf("table-cmplx-accordion-btn") > -1 || tdx.indexOf("table-cmplx-row-select") > -1) {
                td = createElement("tr");
                td.innerHTML = tdx;
                tr.appendChild(td.firstChild);
            } else {
                td = createElement("td");
                td.innerHTML = tdx;
                td.data = tdx;
                var txt = getText(tdx);
                txt = txt ? txt : "";
                td.setAttribute("data-content", txt);
                tr.appendChild(td);
            }
        });

        if (row.details) {
            tr.details = row.details;
        }
        return tr;
    };

    this.render = function (row) {
        return row;
    };

    /**
     * Add new row
     * @param {Array} select
     */
    this.add = function (data, details) {
        if (isArray(data)) {
            // Check for multiple rows
            if (isArray(data[0])) {
                each(
                    data,
                    function (row, i) {
                        // append details is supplied
                        if (details[i]) {
                            row.details = details[i];
                        }
                        dt.data.push(this.build(row));
                    },
                    this
                );
            } else {
                // append details is supplied
                if (details) {
                    data.details = details[0];
                }
                dt.data.push(this.build(data));
            }

            // We may have added data to an empty table
            if (dt.data.length) {
                dt.hasRows = true;
            }

            this.update();

            // Needs to be called after the return of add
        }
    };

    /**
     * Remove row(s)
     * @param  {Array|Number} select
     * @return {Void}
     */
    this.remove = function (select) {
        if (isArray(select)) {
            // Remove in reverse otherwise the indexes will be incorrect
            select.sort(function (a, b) {
                return b - a;
            });

            each(select, function (row) {
                dt.data.splice(row, 1);
            });
        } else {
            dt.data.splice(select, 1);
        }

        this.update();
    };

    /**
     * Update row indexes
     * @return {Void}
     */
    this.update = function () {
        var idx = 0;
        each(dt.data, function (row) {
            if (row.children.length != 1) {
                row.dataIndex = idx++;
            }
        });
    };
}
