/* eslint-disable quotes */
import { jsonOptionsInit, createElement, uicoreCustomEvent } from "./utilities.js";

// eslint-disable-next-line no-unused-vars
export default function FileUpload(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.extensionErrorMessage = options.extensionErrorMessage || "File must be of type {extensions}.";
    options.sizeErrorMessage = options.sizeErrorMessage || "File must be smaller than {max-size}.";

    // constants, event targets, strings
    var self = this,
        stringFileUpload = "FileUpload",
        parent = element.parentNode,
        input = null,
        uploadButton = null,
        target = null,
        placeholder = null,
        maxSize = null,
        maxSizeLabel = null,
        fileNameLimitSize = 22,
        fileNameCropSize = 8,
        // unused
        // feedBackBlock = null,
        hasBeforeDelete = options.beforeDelete && options.beforeDelete instanceof Function,
        hasAfterDelete = options.afterDelete && options.afterDelete instanceof Function,
        hasUploadAction = options.uploadAction && options.uploadAction instanceof Function,
        hasProgressAction = options.progressAction && options.progressAction instanceof Function,
        hasErrorAction = options.errorAction && options.errorAction instanceof Function,
        hasBeforeUpload = options.beforeUpload && options.beforeUpload instanceof Function,
        sizeToBytes = function (size) {
            var originalSize = size.replace(/[^0-9]*/gi, ""),
                suffix = size.replace(/[0-9]*/gi, "").toUpperCase(),
                factor = 1;
            switch (suffix) {
                case "BYTES":
                case "":
                    break;
                case "KB":
                    factor = 1024;
                    break;
                case "MB":
                    factor = 1024000;
                    break;
                default:
                    break;
            }
            return originalSize * factor;
        },
        bytesToSize = function (size) {
            var suffix = "bytes";
            var isKB = size >= 1024 && size < 1024000;
            var isMB = size >= 1024000;
            if (isKB) {
                suffix = "KB";
                size = getRounded(size, 1024);
            } else if (isMB) {
                suffix = "MB";
                size = getRounded(size, 1024000);
            }
            return { suffix: suffix, size: size };
        },
        getRounded = function (size, value) {
            return Math.round((size / value) * 100) / 100;
        },
        removeFile = function (e) {
            e.preventDefault();
            var tempName = element.files[0] ? element.files[0].name : "";
            if (hasBeforeDelete) {
                options.beforeDelete(tempName);
                uicoreCustomEvent("FileUpload", "BeforeDeleteEvent", element, tempName);
            }
            element.value = "";
            renderFileList();
            if (hasAfterDelete) {
                options.afterDelete(tempName);
                uicoreCustomEvent("FileUpload", "AfterDeleteEvent", element, tempName);
            }
            return true;
        },
        loaded = function (evt) {
            var fileString = evt.target.result;
            if (hasUploadAction) {
                options.uploadAction(fileString);
                uicoreCustomEvent("FileUpload", "UploadActionEvent", element, fileString);
            }
        },
        updateProgress = function (evt) {
            if (evt.lengthComputable) {
                var loaded = evt.loaded / evt.total;
                if (hasProgressAction) {
                    options.progressAction({ loaded: loaded, total: evt.total });
                    uicoreCustomEvent("FileUpload", "ProgressActionEvent", element, {
                        loaded: loaded,
                        total: evt.total,
                    });
                }
            }
        },
        errorHandler = function (evt) {
            if (hasErrorAction) {
                options.errorAction(evt.target.error);
                uicoreCustomEvent("FileUpload", "ErrorActionEvent", element, evt.target.error);
            }
        },
        loadFile = function (readFile) {
            var beforeEventReturn = true;
            if (hasBeforeUpload) {
                beforeEventReturn = options.beforeUpload(readFile) !== false;
                uicoreCustomEvent("FileUpload", "BeforeUploadEvent", element, readFile);
            }
            if (window.File && window.FileReader && beforeEventReturn) {
                var reader = new FileReader();
                switch (options.loadFormat) {
                    case "binary":
                        reader.readAsBinaryString(readFile, "UTF-8");
                        break;
                    case "buffer":
                        reader.readAsArrayBuffer(readFile, "UTF-8");
                        break;
                    case "dataUrl":
                        reader.readAsDataURL(readFile, "UTF-8");
                        break;
                    default:
                        reader.readAsText(readFile, "UTF-8");
                        break;
                }
                reader.onprogress = updateProgress;
                reader.onload = loaded;
                reader.onerror = errorHandler;
            }
        },
        errorTemplate = function (message) {
            var Container = createElement("div", {
                class: "dds__alert",
                role: "alert",
            });

            var bodyAlign = createElement("div", {
                class: "dds__d-flex dds__justify-content-between",
            });

            var body = createElement("div", {
                class: "dds__error-default-body",
            });

            var icon = createElement("i", {
                class: "dds__icons dds__alert-notice",
                aria_hidden: "true",
            });

            var messageBox = createElement("p", { class: "dds__alert-body" });
            var messageText = document.createTextNode(message);

            Container.appendChild(bodyAlign);
            bodyAlign.appendChild(body);
            body.appendChild(icon);
            body.appendChild(messageBox);
            messageBox.appendChild(messageText);

            return Container;
        },
        validateSize = function (file) {
            var isTooBig = maxSize && file && maxSize < file.size;
            if (isTooBig) {
                return errorTemplate(
                    options.sizeErrorMessage.replace("{max-size}", maxSizeLabel.size + "" + maxSizeLabel.suffix)
                );
            }
            return false;
        },
        validateExtension = function (fileName) {
            var isInvalidExtension =
                options.allowedExensions &&
                fileName &&
                options.allowedExensions.indexOf(fileName.replace(/^.*\.(.+)$/i, "$1").toLowerCase()) == -1;
            if (isInvalidExtension) {
                return errorTemplate(
                    options.extensionErrorMessage.replace(
                        "{extensions}",
                        options.allowedExensions.join(", ").replace(/^(.+), (.+)$/, "$1 or $2")
                    )
                );
            }
            return false;
        },
        getFileName = function (name) {
            return name.length < fileNameLimitSize
                ? name
                : name.substr(0, fileNameCropSize) + "..." + name.substr(-fileNameCropSize);
        },
        renderFileList = function () {
            target.innerHTML = "";
            for (var index = 0; index < element.files.length; index++) {
                var file = element.files[index],
                    bytesToSizeInfo = bytesToSize(file.size),
                    size = bytesToSizeInfo.size,
                    suffix = bytesToSizeInfo.suffix,
                    name = getFileName(file.name);

                loadFile(file);

                var resultLine = createElement("div", {
                    class: "dds__form-text",
                    data_key: index,
                    aria_labelledby: "Selected file " + name,
                });

                var icon = createElement("i", {
                    class: "dds__icons dds__paper-clip",
                    aria_hidden: "true",
                });

                var fileSizeBox = createElement("span", {
                    class: "dds__form-upload-file-size dds__text-muted",
                    arial_label: size + "" + suffix,
                });

                var iconDelete = createElement("i", {
                    class: "dds__icons dds__trash",
                    aria_hidden: "true",
                });

                var fileSizeText = document.createTextNode(size + "" + suffix);
                var fileNameText = document.createTextNode(name);
                var buttonRemoveText = document.createTextNode("Delete");

                var buttonRemove = createElement("button", {
                    class: "dds__btn dds__form-upload-file-delete dds__btn-link",
                    aria_labelledby: "Delete the file " + name,
                });

                resultLine.appendChild(icon);
                resultLine.appendChild(fileNameText);
                fileSizeBox.appendChild(fileSizeText);
                resultLine.appendChild(fileSizeBox);
                buttonRemove.appendChild(iconDelete);
                buttonRemove.appendChild(buttonRemoveText);
                resultLine.appendChild(buttonRemove);

                buttonRemove.addEventListener("click", removeFile);

                var sizeError = validateSize(file);
                if (sizeError) {
                    resultLine.appendChild(sizeError);
                } else {
                    var extensionError = validateExtension(file.name);
                    if (extensionError) {
                        resultLine.appendChild(extensionError);
                    }
                }

                target.appendChild(resultLine);
            }
        },
        uploadButtonClick = function () {
            input.click();
        };

    // init
    if (!(stringFileUpload in element)) {
        if (element.dataset.maxSize) {
            maxSize = sizeToBytes(element.dataset.maxSize);
            maxSizeLabel = bytesToSize(maxSize);
        }

        input = parent.querySelector(".dds__form-upload");
        uploadButton = parent.querySelector(".dds__form-upload-btn");
        uploadButton.addEventListener("click", uploadButtonClick);

        // unused
        // feedBackBlock = parent.querySelector(".dds__invalid-feedback");

        target = createElement("div");
        target.classList.add("dds__form-upload-target");
        parent.parentNode.appendChild(target);
        element.addEventListener("change", renderFileList, false);

        if (element.dataset.placeholder) {
            placeholder = createElement("div", {
                class: "dds__form-upload-placeholder dds__text-muted",
            });

            var placeholderText = document.createTextNode(element.dataset.placeholder);
            placeholder.appendChild(placeholderText);

            parent.appendChild(placeholder);
        }
    }

    element[stringFileUpload] = self;
}
