import { uicoreCustomEvent, prefix, emulateTransitionEnd, getClosest, DOC, jsonOptionsInit } from "./utilities.js";

export default function Collapse(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.target = element.dataset.target
        ? element.dataset.target
        : options.target
        ? options.target
        : element.href && element.getAttribute("href")
        ? element.getAttribute("href")
        : false;
    options.parent = element.dataset.parent ? element.dataset.parent : options.parent ? options.parent : false;

    // validate options
    (function () {
        if (!options.target || !(options.target.charAt(0) === "#")) {
            throw new Error("There was a problem found with target value, please correct and try again");
        }
    })();

    // event targets and constants
    var self = this,
        accordion = null,
        stringCollapse = "Collapse",
        collapse = null,
        activeCollapse,
        activeElement,
        showAllBtn,
        hideAllBtn,
        isClickable = true,
        // private methods
        openAction = function (collapseElement, toggle) {
            uicoreCustomEvent("ShowHide", "ShowEvent", collapseElement);
            collapseElement.isAnimating = true;
            collapseElement.classList.add(prefix + "collapsing");
            collapseElement.classList.remove(prefix + "collapse");
            collapseElement.style.height = collapseElement.scrollHeight + "px";
            collapseElement.children[0].focus();
            toggle.setAttribute("aria-expanded", "true");

            emulateTransitionEnd(collapseElement, function () {
                if (!toggle.classList.contains(prefix + "collapsed")) {
                    collapseElement.isAnimating = false;
                    collapseElement.setAttribute("aria-expanded", "true");
                    collapseElement.classList.remove(prefix + "collapsing");
                    collapseElement.classList.add(prefix + "collapse");
                    collapseElement.classList.add(prefix + "show");
                    collapseElement.style.height = "";
                    uicoreCustomEvent("ShowHide", "Shown", collapseElement);
                }
            });
        },
        closeAction = function (collapseElement, toggle) {
            uicoreCustomEvent("ShowHide", "HideEvent", collapseElement);
            collapseElement.isAnimating = true;
            collapseElement.style.height = collapseElement.scrollHeight + "px"; // set height first
            collapseElement.classList.remove(prefix + "collapse");
            collapseElement.classList.remove(prefix + "show");
            collapseElement.classList.add(prefix + "collapsing");
            collapseElement.offsetWidth; // force reflow to enable transition
            collapseElement.style.height = "0rem";
            toggle.setAttribute("aria-expanded", "false");

            emulateTransitionEnd(collapseElement, function () {
                collapseElement.isAnimating = false;
                collapseElement.setAttribute("aria-expanded", "false");
                collapseElement.classList.remove(prefix + "collapsing");
                collapseElement.classList.add(prefix + "collapse");
                collapseElement.style.height = "";
                uicoreCustomEvent("ShowHide", "Hidden", collapseElement);
            });
        },
        showAll = function () {
            // this function will be called by all the sub accordions individually
            // thus, achieve the goal to show all
            if (element.classList.contains(prefix + "collapsed")) {
                self.show();
            }
        },
        hideAll = function () {
            if (!element.classList.contains(prefix + "collapsed")) {
                self.hide();
            }
        },
        handleBeginShow = function () {
            isClickable = false;
        },
        handleEndShow = function () {
            isClickable = true;
        };

    // public methods
    this.toggle = function (e) {
        if (!isClickable) {
            return;
        }
        if (e) {
            e.preventDefault();
        }
        if (!collapse.classList.contains(prefix + "show")) {
            self.show();
        } else {
            self.hide();
        }
    };
    this.hide = function () {
        if (collapse.isAnimating) return;
        closeAction(collapse, element);
        element.classList.add(prefix + "collapsed");
    };
    this.show = function () {
        if (accordion && options.parent) {
            activeCollapse =
                accordion.querySelector("." + prefix + "collapse" + "." + prefix + "show") ||
                accordion.querySelector("." + prefix + "collapsing");
            activeElement =
                activeCollapse &&
                (accordion.querySelector(
                    "[data-toggle='" + prefix + "collapse" + "'][data-target='#" + activeCollapse.id + "']"
                ) ||
                    accordion.querySelector(
                        "[data-toggle='" + prefix + "collapse" + "'][href='#" + activeCollapse.id + "']"
                    ));
        }

        if (!collapse.isAnimating || (activeCollapse && !activeCollapse.isAnimating)) {
            if (activeElement && activeCollapse !== collapse) {
                closeAction(activeCollapse, activeElement);
                activeElement.classList.add(prefix + "collapsed");
            }
            openAction(collapse, element);
            element.classList.remove(prefix + "collapsed");
        }
    };

    // init
    if (!(stringCollapse in element)) {
        element.addEventListener("click", self.toggle, false);

        collapse = DOC.getElementById(options.target.substr(1));
        if (collapse) {
            collapse.isAnimating = false; //// when true it will prevent click handlers
        }
        accordion =
            (options.parent && getClosest(element, options.parent)) || getClosest(element, "." + prefix + "accordion");

        if (accordion) {
            var showHideAll = accordion.querySelector(".dds__show-hide-container");
            if (showHideAll) {
                showAllBtn = showHideAll.querySelector(".dds__show-all");
                if (showAllBtn) {
                    showAllBtn.addEventListener("click", showAll, false);
                }
                hideAllBtn = showHideAll.querySelector(".dds__hide-all");
                if (hideAllBtn) {
                    hideAllBtn.addEventListener("click", hideAll, false);
                }
            }
        }
        document.addEventListener("uicShowHideShowEvent", handleBeginShow);
        document.addEventListener("uicShowHideShown", handleEndShow);
    }

    element[stringCollapse] = self;
}
