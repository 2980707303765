import {
    DOC,
    emulateTransitionEnd,
    getFocusableElements,
    getFullScreenOverlay,
    getTransitionDurationFromElement,
    prefix,
    supportTransitions,
    uicoreCustomEvent,
    isSafari,
    isIOS,
    each,
    setFocus,
    jsonOptionsInit,
    getClosest,
} from "./utilities.js";

export default function Modal(element, options) {
    // element can be the modal/triggering button

    // the modal (both JavaScript / DATA API init) / triggering button element (DATA API)
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.static = options.backdrop === "static" ? true : false;
    options.target = element.dataset.target
        ? DOC.getElementById(element.dataset["target"].substr(1))
        : options.target
        ? DOC.getElementById(options.target.substr(1))
        : null;
    // validate options
    (function () {
        if (options.target == null || options.target === "") {
            throw new Error("There was a problem found with target value, please correct and try again");
        }
    });
    // determine modal, triggering element
    var self = this,
        stringModal = "Modal",
        focusableEls,
        header,
        origRightPadding,
        scrollbarWidth,
        modalElement = options.target,
        overlayDelay,
        overlay = getFullScreenOverlay(),
        // private methods
        setFocusableElements = function () {
            focusableEls = getFocusableElements(modalElement);
            modalElement.firstFocusableEl = focusableEls[0];
            modalElement.lastFocusableEl = focusableEls[focusableEls.length - 1];
        },
        focusFirstDescendant = function () {
            if (modalElement.loading) {
                //if loading indicator modal- trap focus so can't tab out
                modalElement.focus();
            } else {
                setFocusableElements();
                if (focusableEls.length > 0) {
                    //if there is something to focus
                    setFocus(focusableEls[0]);
                }
            }
        },
        handleKeyDown = function (e) {
            if (!modalElement.loading) {
                setFocusableElements();

                var KEY_TAB = 9;
                var KEY_ESC = 27;

                // For loading modal which has no focusable elements
                if (focusableEls.length == 0) {
                    e.preventDefault();
                }

                switch (e.keyCode) {
                    case KEY_TAB:
                        if (focusableEls.length === 1) {
                            e.preventDefault();
                            break;
                        }
                        if (e.shiftKey) {
                            handleBackwardTab(e);
                        } else {
                            handleForwardTab(e);
                        }
                        break;
                    case KEY_ESC:
                        self.hide();
                        break;
                    default:
                        break;
                }
            }
        },
        handleBackwardTab = function (e) {
            if (document.activeElement === modalElement.firstFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.lastFocusableEl);
            }
            if (header) focusVisibilityHandler();
        },
        handleForwardTab = function (e) {
            if (header) focusVisibilityHandler();

            if (document.activeElement === modalElement.lastFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.firstFocusableEl);
            }
        },
        createOverlay = function () {
            if (overlay && !overlay.classList.contains(prefix + "show")) {
                overlayDelay = getTransitionDurationFromElement(overlay);
                overlay.classList.add(prefix + "show");
                overlay.removeAttribute("hidden");
                if (overlay.style.visibility == "hidden") {
                    overlay.style.visibility = "";
                }
            } else {
                console.warn(
                    "MODAL: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div"
                );
            }

            if (!options.static) {
                window.addEventListener(
                    "click",
                    function (e) {
                        if (
                            modalElement.classList.contains(prefix + "show") &&
                            e.target != modalElement &&
                            !modalElement.contains(e.target)
                        ) {
                            self.hide();
                        }
                    },
                    false
                );
            }
            DOC.body.style.paddingRight = scrollbarWidth + origRightPadding + "px";
            DOC.body.style.overflow = "hidden";
        },
        removeOverlay = function () {
            if (overlay) {
                overlay.classList.remove(prefix + "show");
                overlay.setAttribute("hidden", "");
            }

            if (!options.static) {
                modalElement.removeEventListener(
                    "click",
                    function (e) {
                        if (e.target == modalElement) {
                            self.hide();
                        }
                    },
                    false
                );
            }
            if (origRightPadding == 0) {
                DOC.body.style.paddingRight = "";
            } else {
                DOC.body.style.paddingRight = origRightPadding + "px";
            }
            DOC.body.style.overflow = "";
            if (DOC.body.style.length == 0) {
                DOC.body.removeAttribute("style");
            }
            uicoreCustomEvent("Modal", "Hidden", modalElement);
        },
        keydownHandlerToggle = function () {
            if (modalElement.classList.contains(prefix + "show")) {
                DOC.addEventListener("keydown", keyHandler, false);
            } else {
                DOC.removeEventListener("keydown", keyHandler, false);
            }
        },
        triggerShow = function () {
            //resizeHandlerToggle();
            keydownHandlerToggle();
            setFocus(modalElement);
            focusFirstDescendant();
            uicoreCustomEvent("Modal", "Shown", modalElement);
        },
        triggerHide = function () {
            setTimeout(function () {
                modalElement.style.display = "";
            }, getTransitionDurationFromElement(modalElement));
            //check if parent is dropdown, and set focus on appropriate element
            var isInDropdown = getClosest(element, "." + prefix + "btn-dropdown", true);
            var isInSplitBtn = isInDropdown && isInDropdown.classList.contains(prefix + "btn-group");
            element &&
                (isInDropdown
                    ? isInSplitBtn
                        ? setFocus(isInDropdown.querySelector("." + prefix + "btn-split-arrow"))
                        : setFocus(isInDropdown.querySelector("." + prefix + "btn"))
                    : setFocus(element));

            if (modalElement.classList.contains(prefix + "show")) {
                keydownHandlerToggle();
            }
        },
        // handlers
        clickHandler = function (e) {
            if (
                (e.target === element || e.target.parentElement === element) &&
                !modalElement.classList.contains(prefix + "show")
            ) {
                self.show();
                e.preventDefault();
            }
        },
        keyHandler = function (e) {
            if (self.keyboard && e.which == 27 && modalElement.classList.contains(prefix + "show")) {
                self.hide();
            }
        },
        dismissHandler = function (e) {
            if (modalElement.classList.contains(prefix + "show")) {
                self.hide();
                e.preventDefault();
            }
        },
        focusVisibilityHandler = function () {
            // minimum distance from "to be focused" element to the bottom of fixed header
            var minDistance = header.parentElement.clientHeight;
            var activeElementIdx = focusableEls.indexOf(document.activeElement);

            // handles shift tab loop, and get next element to receive focus - going back
            var prevFocusableEl =
                activeElementIdx === 0 ? focusableEls[focusableEls.length - 1] : focusableEls[activeElementIdx - 1];

            // handles tab loop, and get next element to receive focus - going forward
            var nextFocusableEl =
                activeElementIdx === focusableEls.length - 1 ? focusableEls[0] : focusableEls[activeElementIdx + 1];

            if (event.shiftKey && event.keyCode === 9) {
                scrollToElement(prevFocusableEl, minDistance, header);
            } else if (!event.shiftKey && event.keyCode === 9) {
                scrollToElement(nextFocusableEl, minDistance, header);
            }
        },
        scrollToElement = function (element, minDistance, header) {
            if (
                !header.contains(element) && // prevents scroll if header will receive focus
                element.getBoundingClientRect().top < minDistance
            ) {
                // element is behind fixed header

                // scroll to top of "to be focused" element
                modalElement.scrollTop = element.getBoundingClientRect().top - minDistance * 2 + modalElement.scrollTop;
            }
        };

    // public methods
    this.toggle = function () {
        if (modalElement.classList.contains(prefix + "show")) {
            this.hide();
        } else {
            this.show();
        }
    };
    this.show = function () {
        uicoreCustomEvent("Modal", "ShowEvent", modalElement);

        createOverlay();

        modalElement.style.display = "block";

        if (isSafari && isIOS) {
            modalElement.classList.add(prefix + "is-safari");
        }

        setTimeout(function () {
            modalElement.classList.add(prefix + "show");
        }, 5);
        modalElement.setAttribute("aria-hidden", false);
        modalElement.classList.contains(prefix + "fade")
            ? emulateTransitionEnd(modalElement, triggerShow)
            : triggerShow();

        supportTransitions && modalElement.loading ? overlayDelay : 0;
    };
    this.hide = function () {
        uicoreCustomEvent("Modal", "HideEvent", modalElement);

        modalElement.classList.remove(prefix + "show");
        modalElement.setAttribute("aria-hidden", true);

        if (isSafari && isIOS && modalElement.classList.contains(prefix + "is-safari")) {
            modalElement.classList.remove(prefix + "is-safari");
        }

        setTimeout(function () {
            removeOverlay();
        }, getTransitionDurationFromElement(modalElement));

        modalElement.classList.contains(prefix + "fade")
            ? emulateTransitionEnd(modalElement, triggerHide)
            : triggerHide();
        supportTransitions && overlay ? overlayDelay : 0;
    };

    //init
    if (!(stringModal in element)) {
        if (!modalElement) {
            return false;
        } else {
            origRightPadding = DOC.body.style.paddingRight ? DOC.body.style.paddingRight.split("px")[0] : 0;
            scrollbarWidth = window.innerWidth - DOC.documentElement.clientWidth;
            modalElement.loading = modalElement.classList.contains(prefix + "loading-modal") ? true : false;

            if (!modalElement.loading) {
                element.addEventListener("click", clickHandler, false);
                modalElement.addEventListener("keydown", handleKeyDown);
                each(modalElement.querySelectorAll("[data-dismiss='" + prefix + "modal']"), function (dissmissEl) {
                    dissmissEl.addEventListener("click", dismissHandler, false);
                });
                var modalContent = modalElement.querySelector("." + prefix + "modal-content");
                var potentialHeader = modalContent.firstElementChild;
                if (
                    potentialHeader.classList.contains(prefix + "container-fluid") &&
                    !potentialHeader.classList.contains(prefix + "modal-body")
                ) {
                    potentialHeader.firstElementChild.classList.add(prefix + "offcanvas-modal-topbar");
                }
                header = modalElement.querySelector("." + prefix + "offcanvas-modal-topbar");
            }
        }
    }

    element[stringModal] = self;
}
