import {
    prefix,
    debounce,
    jsonOptionsInit,
    getFullScreenOverlay,
    validateNum,
    uicoreCustomEvent,
    each,
    getFocusableElements,
    getClosest,
    isIE,
    isEdge,
    isFirefox,
    DOC,
    createElement,
    globalObject,
} from "./utilities.js";
import InputMask from "./helpers/inputMask.js";

export function Spinbox(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.spinmin = element.dataset.spinmin
        ? validateNum(element.dataset.spinmin, 0)
        : validateNum(options.spinmin, 0);
    options.spindefault = element.dataset.spindefault
        ? validateNum(element.dataset.spindefault, 0)
        : validateNum(options.spindefault, 0);
    options.spinmax = element.dataset.spinmax
        ? validateNum(element.dataset.spinmax, 100)
        : validateNum(options.spinmax, 100);
    options.spinstep = element.dataset.spinstep
        ? validateNum(element.dataset.spinstep, 1)
        : validateNum(options.spinstep, 1);

    var msg = "";
    if (isNaN(options.spinmin)) {
        msg += "Min value is not a number.\n";
    }
    if (isNaN(options.spinmax)) {
        msg += "Max value is not a number.\n";
    }
    if (isNaN(options.spindefault)) {
        msg += "Default value is not a number.\n";
    } else if (options.spindefault < options.spinmin || options.spindefault > options.spinmax) {
        msg += "Default value falls outside of the min max values.\n";
    }
    if (isNaN(options.spinstep)) {
        msg += "Step value is not a number.\n";
    }
    if (msg.length) {
        throw new Error(msg);
    }

    var self = this,
        stringSpinbox = "Spinbox",
        updateValue = function (value) {
            input.value = value;
            input.setAttribute("aria-valuenow", value);
            //the attr size is what defines an input width
            var size = Math.max(2, value.toString().length);
            input.setAttribute("size", size.toString());
            if (isIE && size > 2) {
                if (size > 3) {
                    element.setAttribute("style", "min-width:" + size + "rem");
                } else {
                    element.removeAttribute("style");
                }
            }
        },
        input,
        plusCtrl,
        minusCtrl,
        // horizontal
        // handlers
        handleKeypressEvent = debounce(function (e) {
            var charCode = e.which ? e.which : e.keyCode;
            // if not a number
            if (input.value.length > options.spinmax.length || (charCode > 31 && (charCode < 48 || charCode > 57))) {
                if (charCode == 13) {
                    input.blur();
                } else if (input.value.length == 0 && options.spinmin < 0 && charCode == 45) {
                    // allow minus when input empty and spinmin less than zero
                    return;
                } else {
                    e.preventDefault();
                }
            } else {
                var newInput = input.value;
                if (parseInt(newInput) > options.spinmax) {
                    updateValue(options.spinmax);
                    e.preventDefault();
                } else if (parseInt(newInput) < options.spinmin) {
                    updateValue(options.spinmin);
                    e.preventDefault();
                } else {
                    if (input.value == "-" && e.charCode == 48) {
                        // dont allow -0
                        e.preventDefault();
                    } else {
                        if (charCode == 13) {
                            // Enter key
                            if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                                input.value =
                                    parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
                            }
                            input.blur();
                        } else {
                            updateValue(input.value);
                        }
                    }
                }
            }
        }, 500),
        handleCtrlClickEvent = function (e, control) {
            var temp;
            if (control === minusCtrl) {
                temp = parseInt(input.value) - options.spinstep;
            } else {
                temp = parseInt(input.value) + options.spinstep;
            }
            if (!(temp < options.spinmin) && !(temp > options.spinmax)) {
                updateValue(temp);
                handleDisabling();
                uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {
                    value: input.value,
                });
            }
        },
        handleDisabling = function () {
            if (input.value == options.spinmin || parseInt(input.value) - options.spinstep < options.spinmin) {
                minusCtrl.setAttribute("disabled", "");
            } else if (minusCtrl.hasAttribute("disabled")) {
                minusCtrl.removeAttribute("disabled");
            }
            if (input.value == options.spinmax || parseInt(input.value) + options.spinstep > options.spinmax) {
                plusCtrl.setAttribute("disabled", "");
            } else if (plusCtrl.hasAttribute("disabled")) {
                plusCtrl.removeAttribute("disabled");
            }
        },
        handleFocusOut = function () {
            // if input is empty, negative, or NaN
            if (input.value.length == 0 || input.value == "-" || !/^\d+$/.test(input.value)) {
                input.value = options.spindefault;
            } else if (parseInt(input.value) < options.spinmin) {
                input.value = options.spinmin;
            } else if (parseInt(input.value) > options.spinmax) {
                input.value = options.spinmax;
            } else if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                input.value = parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
            } else if (input.value.match(/^0+/g)) {
                var match = input.value.match(/^0+/g);
                var temp = input.value.replace(match, "");
                if (temp.length == 0) {
                    input.value = "0";
                } else {
                    input.value = temp;
                }
            }
            handleDisabling();
            uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {
                value: input.value,
            });
        },
        handleArrowEvent = function (e) {
            switch (e.keyCode) {
                case 35: // end
                    e.preventDefault();
                    input.value = options.spinmax;
                    uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {
                        value: input.value,
                    });
                    break;
                case 36: // home
                    e.preventDefault();
                    input.value = options.spinmin;
                    uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {
                        value: input.value,
                    });
                    break;
                case 38: // up
                    e.preventDefault();
                    if (input.value.length) {
                        plusCtrl.click();
                    } else {
                        var v = options.spinmin + options.spinstep;
                        updateValue(v);
                        uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, { value: v });
                    }
                    break;
                case 40: // down
                    e.preventDefault();
                    minusCtrl.click();
                    break;
                case 37: // left
                case 39: // right
                    e.preventDefault();
                    break;
                default:
                    break;
            }
        };

    // init
    if (!(stringSpinbox in element)) {
        input = element.querySelector("." + prefix + "spinbox-input");
        input.addEventListener("keypress", handleKeypressEvent);
        input.addEventListener("keyup", handleDisabling);
        input.addEventListener("focusout", handleFocusOut);
        element.addEventListener("keydown", handleArrowEvent);

        input.value = options.spindefault;
        input.setAttribute("aria-valuenow", options.spindefault);
        input.setAttribute("aria-valuemin", options.spinmin);
        input.setAttribute("aria-valuemax", options.spinmax);

        var controls = element.querySelectorAll("button." + prefix + "spinbox-btn");
        if (element.classList.contains(prefix + "spinbox-horizontal")) {
            // horizontal spinbox
            // horizontal = true;
            minusCtrl = controls[0];
            plusCtrl = controls[1];
        } else {
            // horizontal = false;
            minusCtrl = controls[1];
            plusCtrl = controls[0];
        }

        minusCtrl.addEventListener("click", function (e) {
            handleCtrlClickEvent(e, minusCtrl);
        });
        plusCtrl.addEventListener("click", function (e) {
            handleCtrlClickEvent(e, plusCtrl);
        });
        handleDisabling();
        updateValue(parseInt(input.value, 10));
    }

    element[stringSpinbox] = self;
}

export function DatePicker(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set option
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.datesFilter = options.datesFilter ? options.datesFilter : false;
    options.pastDates = options.pastDates ? options.pastDates === true : false;
    options.availableWeekDays = options.availableWeekDays
        ? options.availableWeekDays
        : [
              { day: "monday" },
              { day: "tuesday" },
              { day: "wednesday" },
              { day: "thursday" },
              { day: "friday" },
              { day: "saturday" },
              { day: "sunday" },
          ];
    options.notBeforeDate = options.notBeforeDate ? new Date(options.notBeforeDate) : null;
    options.notAfterDate = options.notAfterDate ? new Date(options.notAfterDate) : null;
    options.button_prev = options.button_prev ? options.button_prev : null;
    options.button_next = options.button_next ? options.button_next : null;

    var self = this,
        stringDatePicker = "DatePicker",
        calendarBtn = element.querySelector("." + prefix + "datepicker-btn"),
        target = element["getAttribute"]("data-target"),
        dateInput = element.querySelector("." + prefix + "form-control"),
        calendar = element.querySelector(target),
        form = null,
        monthEl = null,
        focusableEls,
        currentSelected,
        overlay = getFullScreenOverlay(),
        smallMedia = window.matchMedia("(max-width: 767.98px)"),
        calendarWrapper,
        tempSelected = new Date(),
        todaysDate = new Date(),
        monthLabel = null,
        // holds the current month in calendar presentation
        datePtr = new Date(),
        monthsTexts = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
        keyCode = Object.freeze({
            TAB: 9,
            ENTER: 13,
            ESC: 27,
            SPACE: 32,
            PAGEUP: 33,
            PAGEDOWN: 34,
            END: 35,
            HOME: 36,
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
        }),
        getMonthName = function (date) {
            return monthsTexts[(date || new Date()).getMonth()];
        },
        setFocusableElements = function () {
            focusableEls = getFocusableElements(calendar);
        },
        toggleCalendar = function () {
            if (calendar.classList.contains(prefix + "d-none")) {
                calendar.classList.remove(prefix + "d-none");
                calendarWrapper.classList.remove(prefix + "hide");
                if (smallMedia.matches) {
                    resetPosition();
                    createOverlay();
                } else {
                    repositionIfNeeded();
                }

                globalObject.addEventListener("resize", toggleCalendar);
                //check if calendar is within confines of page
                var dateText = dateInput.value;
                // !! new Date(dateText) risk of bug when browser l10n/i18n <> en_US
                if (dateText && dateInput.validity.valid && checkDateValidity(new Date(dateText))) {
                    currentSelected = new Date(dateText);
                    tempSelected = new Date(dateText);
                    if (datePtr.getFullYear() != currentSelected.getFullYear()) {
                        datePtr.setFullYear(currentSelected.getFullYear());
                    }
                    if (datePtr.getMonth() != currentSelected.getMonth()) {
                        datePtr.setMonth(currentSelected.getMonth());
                    }
                    createMonth();
                    var newDateElem = element.querySelector('[data-calendar-date="' + currentSelected + '"]');
                    if (!newDateElem.classList.contains(prefix + "datepicker-date-disabled")) {
                        newDateElem.classList.add(prefix + "datepicker-date-selected");
                        var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
                        nextBtn.removeAttribute("tabindex");
                        nextBtn.focus();
                    }
                    tempSelected = new Date(currentSelected.getTime());
                } else {
                    resetToDefaultDate();
                }
                calendar.addEventListener("focusout", blurHandler, false);
            } else {
                calendar.classList.add(prefix + "d-none");
                calendarWrapper.classList.add(prefix + "hide");
                globalObject.removeEventListener("resize", toggleCalendar);
                removeActiveClass();
                calendarBtn.focus();
                calendar.removeEventListener("focusout", blurHandler, false);
                removeOverlay();
            }
        },
        createWrapper = function () {
            var wrapper = createElement("div", {
                class: prefix + "calendar-wrapper " + prefix + "hide",
            });
            calendar.parentNode.appendChild(wrapper);
            wrapper.appendChild(calendar);
            return wrapper;
        },
        resetPosition = function () {
            calendar.style.top = "";
            calendar.style.right = "";
            calendar.style.bottom = "";
        },
        repositionIfNeeded = function () {
            resetPosition();
            var elementRect = element.getBoundingClientRect();
            var calendarRect = calendar.getBoundingClientRect();

            if (calendarRect.right > document.body.offsetWidth) {
                calendar.style.right = document.body.offsetWidth - calendarBtn.getBoundingClientRect().right + "px";
            }

            var elementCenterPercent = parseInt(
                (100 * (elementRect.top + elementRect.height / 2)) / window.innerHeight
            );
            var elementExceedsTop = elementRect.top < 0;
            var elementExceedsBottom = elementRect.top + elementRect.height > window.innerHeight;

            var scrHeightRemain = Math.max(window.innerHeight - calendarRect.height, 0);
            var scrHeightRemainProportional = parseInt(scrHeightRemain * (elementCenterPercent / 100));

            var calendarExceedsBottom = calendarRect.bottom >= window.innerHeight;
            var calendarExceedsTop = elementRect.top - calendarRect.height <= 0;
            var calendarFitScr = calendarRect.height <= window.innerHeight;
            var calendarTopToReachProportion = Math.max(elementRect.top - scrHeightRemainProportional, 0);

            if (!calendarExceedsBottom) {
                calendar.style.top = "100%";
            } else if (!calendarExceedsTop) {
                calendar.style.bottom = "100%";
            } else if (calendarFitScr && elementExceedsTop) {
                calendar.style.top = Math.abs(elementRect.top) + "px";
            } else if (calendarFitScr && elementExceedsBottom) {
                calendar.style.bottom = Math.abs(elementRect.top + elementRect.height - window.innerHeight) + "px";
            } else if (calendarFitScr) {
                calendar.style.top = "-" + calendarTopToReachProportion + "px";
            } else {
                calendar.style.top = "-" + Math.abs(elementRect.top) + "px";
            }
        },
        blurHandler = function () {
            setTimeout(function () {
                if (
                    calendar &&
                    !calendar.contains(document.activeElement) &&
                    !calendarBtn.contains(document.activeElement)
                ) {
                    toggleCalendar();
                    calendarBtn.focus();
                }
            }, 10);
        },
        getWeekDay = function (day) {
            return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][day];
        },
        __formatDate = function (d, mask) {
            function pad(n, z) {
                var v = String(n);
                while (v.length < z) {
                    v = "0" + v;
                }
                return v;
            }

            if (!mask || mask.length === 0) {
                mask = "YMD";
            }
            var o = "";
            for (var i = 0; i < mask.length; i++) {
                switch (mask[i]) {
                    case "Y":
                        o = o + pad(d.getFullYear(), 4);
                        break;
                    case "M":
                        o = o + pad(d.getMonth(), 2);
                        break;
                    case "D":
                        o = o + pad(d.getDate(), 2);
                        break;
                }
            }
            return o;
        },
        __createDayWrapper = function (date) {
            var div = document.createElement("div");
            div.className = prefix + "datepicker-date";
            div.setAttribute("data-calendar-date", date);
            return div;
        },
        __createDayBtn = function (date) {
            var btn = document.createElement("button");
            btn.setAttribute("type", "button");
            btn.setAttribute("tabindex", "-1");
            btn.className = prefix + "datepicker-calendar-day";
            btn.addEventListener("keydown", dateKeydownHandler, false);
            btn.innerHTML = date.getDate();
            btn.setAttribute("aria-label", [weekDays[date.getDay()], getMonthName(date), date.getDate()].join(" "));
            return btn;
        },
        __generateDateRange = function (startDate, endDate) {
            var dates = [];
            var idate = new Date(startDate);
            var udate = new Date(endDate);
            while (idate <= udate) {
                dates.push(new Date(idate));
                idate.setDate(idate.getDate() + 1);
            }
            return dates;
        },
        // generate date series pertaining a calendar in base date's month/year
        // base must be a Date object!
        __generateCalendar = function (base) {
            var baseDate = !base ? new Date() : base;

            var year = baseDate.getFullYear(),
                month = baseDate.getMonth();

            var firstDay = new Date(year, month, 1);
            if (firstDay.getDay() > 0) {
                firstDay.setDate(firstDay.getDate() - firstDay.getDay());
            }

            var lastDay = new Date(year, month + 1, 1);
            lastDay.setDate(lastDay.getDate() - 1);

            lastDay.setDate(lastDay.getDate() + (6 - lastDay.getDay()));
            // generate the list of dates
            return __generateDateRange(firstDay, lastDay);
        },
        createDay = function (date) {
            var isAvailable = true;

            var dateElem = __createDayBtn(date);
            var newDayElem = __createDayWrapper(date);

            if (date.getMonth() != datePtr.getMonth()) {
                newDayElem.classList.add(prefix + "datepicker-date-outdated");
            }

            var available_week_day = options.availableWeekDays.filter(function (f) {
                return f.day === date.getDay() || f.day === getWeekDay(date.getDay());
            });
            if (date <= options.defaultDate && date.getDate() < options.defaultDate.getDate() && !options.pastDates) {
                newDayElem.classList.add(prefix + "datepicker-date-disabled");
                dateElem.setAttribute("disabled", "");
            } else {
                if (options.datesFilter) {
                    if (options.notBeforeDate && date <= options.notBeforeDate) {
                        isAvailable = false;
                    }
                    if (options.notAfterDate && date > options.notAfterDate) {
                        isAvailable = false;
                    }
                    if (available_week_day.length && isAvailable) {
                        newDayElem.classList.add(prefix + "datepicker-date-active");
                        newDayElem.setAttribute("data-calendar-data", JSON.stringify(available_week_day[0]));
                        newDayElem.setAttribute("data-calendar-status", "active");
                    } else {
                        newDayElem.classList.add(prefix + "datepicker-date-disabled");
                        dateElem.setAttribute("disabled", "");
                    }
                } else {
                    newDayElem.classList.add(prefix + "datepicker-date-active");
                    newDayElem.setAttribute("data-calendar-status", "active");
                }
            }
            //set current selected date
            if (
                currentSelected &&
                date.toString() == currentSelected.toString() &&
                !newDayElem.classList.contains(prefix + "datepicker-date-disabled")
            ) {
                newDayElem.classList.add(prefix + "datepicker-date-selected");
            }
            //if textbox is empty
            if (
                date.toString() == tempSelected.toString() &&
                !newDayElem.classList.contains(prefix + "datepicker-date-disabled")
            ) {
                newDayElem.classList.add(prefix + "datepicker-date-temp-selected");
                dateElem.removeAttribute("tabindex");
            }
            //add date to the calendar
            newDayElem.appendChild(dateElem);
            return newDayElem;
        },
        createOverlay = function () {
            DOC.body.style.overflow = "hidden";
            if (overlay && !overlay.classList.contains(prefix + "show")) {
                overlay.classList.add(prefix + "show");
                overlay.removeAttribute("hidden");
                if (overlay.style.visibility == "hidden") {
                    overlay.style.visibility = "";
                }
            } else {
                console.warn(
                    "MODAL: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div"
                );
            }
        },
        removeOverlay = function () {
            DOC.body.style.overflow = "";
            if (overlay) {
                overlay.classList.remove(prefix + "show");
            }
        },
        removeActiveClass = function () {
            each(element.querySelectorAll("." + prefix + "datepicker-date-selected"), function (day) {
                day.classList.remove(prefix + "datepicker-date-selected");
            });
        },
        removeTempClass = function () {
            each(element.querySelectorAll("." + prefix + "datepicker-date-temp-selected"), function (day) {
                day.classList.remove(prefix + "datepicker-date-temp-selected");
                day.firstElementChild.setAttribute("tabindex", "-1");
            });
        },
        resetToDefaultDate = function () {
            currentSelected = null;
            var defaultDate = getNearestAvailableDay(
                new Date(options.defaultDate),
                options.notBeforeDate,
                options.notAfterDate
            );
            if (!defaultDate) {
                /* eslint-disable no-console */
                console.warn(
                    "Can't define a valid date range. Check options.defaultDate options.notBeforeDate options.notAfterDate"
                );
                return;
            }
            //todaysDate = getNearestAvailableDay(todaysDate);
            tempSelected = new Date(defaultDate.getTime());
            if (datePtr.getFullYear() != tempSelected.getFullYear()) {
                datePtr.setFullYear(tempSelected.getFullYear());
            }
            if (datePtr.getMonth() != tempSelected.getMonth()) {
                datePtr.setMonth(tempSelected.getMonth());
            }
            createMonth();
            var newDateElem = element.querySelector('[data-calendar-date="' + tempSelected + '"]');
            newDateElem.classList.add(prefix + "datepicker-date-temp-selected");
            var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
            nextBtn.removeAttribute("tabindex");
            nextBtn.focus();
        },
        selectDate = function () {
            var activeDates = element.querySelectorAll("[data-calendar-status=active]");
            each(activeDates, function (date) {
                date.addEventListener("click", function () {
                    removeActiveClass();
                    removeTempClass();
                    var datas = this.dataset;
                    this.classList.add(prefix + "datepicker-date-selected");
                    currentSelected = new Date(datas.calendarDate);
                    setNewDate();
                });
            });
        },
        createMonth = function () {
            clearCalendar();
            monthLabel.innerHTML = monthsTexts[datePtr.getMonth()] + " " + datePtr.getFullYear();

            // clone current date
            var previousPtr = new Date(datePtr.getTime()),
                nextPtr = new Date(datePtr.getTime());

            // adjust new datePtr in previous & next month
            previousPtr.setMonth(previousPtr.getMonth() - 1);
            nextPtr.setMonth(nextPtr.getMonth() + 1);

            //check to see if previous month is out of range
            if (
                (options.notBeforeDate &&
                    __formatDate(previousPtr, "YM") < __formatDate(options.notBeforeDate, "YM")) ||
                (!options.pastDates && __formatDate(previousPtr, "YM") < __formatDate(todaysDate, "YM"))
            ) {
                options.button_prev.classList.add(prefix + "disabled");
                options.button_prev.setAttribute("disabled", "");
            } else {
                options.button_prev.classList.remove(prefix + "disabled");
                options.button_prev.removeAttribute("disabled");
            }
            //check to see if next month is out of range
            if (options.notAfterDate && __formatDate(nextPtr, "YM") > __formatDate(options.notAfterDate, "YM")) {
                options.button_next.classList.add(prefix + "disabled");
                options.button_next.setAttribute("disabled", "");
            } else {
                options.button_next.classList.remove(prefix + "disabled");
                options.button_next.removeAttribute("disabled");
            }
            //add next month days onto calendar

            var dates = __generateCalendar(datePtr);
            each(dates, function (d) {
                monthEl.appendChild(createDay(d));
            });

            datePtr.setDate(1);
            selectDate();
            setFocusableElements();
        },
        updateMonthNavLabel = function (date) {
            //update label with prev month
            var prevMonthDate = new Date(date);
            prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
            options.button_prev &&
                options.button_prev.setAttribute(
                    "aria-label",
                    ["previous month ", getMonthName(prevMonthDate)].join(" ")
                );

            //update label with next month
            var nextMonthDate = new Date(date);
            nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
            options.button_next &&
                options.button_next.setAttribute("aria-label", ["next month ", getMonthName(nextMonthDate)].join(" "));
        },
        monthPrev = function (e) {
            if (e) {
                e.preventDefault();
            }
            datePtr.setMonth(datePtr.getMonth() - 1);
            createMonth();
        },
        monthNext = function (e) {
            if (e) {
                e.preventDefault();
            }
            datePtr.setMonth(datePtr.getMonth() + 1);
            createMonth();
        },
        checkDateValidity = function (date) {
            if (isNaN(date.getTime())) {
                return false;
            }
            var available_week_day = options.availableWeekDays.filter(function (f) {
                return f.day === date.getDay() || f.day === getWeekDay(date.getDay());
            });
            //check if valid weekday
            if (available_week_day.length == 0) {
                return false;
            }
            //check if not before
            if (options.notBeforeDate && date <= options.notBeforeDate) {
                return false;
            }
            //check if not after
            if (options.notAfterDate && date > options.notAfterDate) {
                return false;
            }
            // check if before today
            if (!options.pastDates && date < options.defaultDate && date.getDate() < options.defaultDate.getDate()) {
                return false;
            }
            return true;
        },
        clearCalendar = function () {
            monthEl.innerHTML = "";
        },
        createCalendar = function () {
            element.setAttribute("aria-modal", "true");
            element.setAttribute("role", "dialog");
            /* eslint-disable */
            element.querySelector(target).innerHTML = [
                '<div class="dds__datepicker-header">',
                '    <button type="button" class="dds__datepicker-nav-btn" aria-label="previous month" data-calendar-toggle="previous">',
                '        <svg focusable="false" class="dds__svg-icons"><use xlink:href="#dds__chevron-left"></use></svg>',
                "    </button>",
                '    <div tabindex="0" class="dds__datepicker-header__label" data-calendar-label="month"></div>',
                '    <button type="button" class="dds__datepicker-nav-btn" aria-label="next month"data-calendar-toggle="next">',
                '        <svg focusable="false" class="dds__svg-icons"><use xlink:href="#dds__chevron-right"></use></svg>',
                "    </button>",
                "</div>",
                '<div class="dds__datepicker-week"></div>',
                '<div class="dds__datepicker-body" role="application" data-calendar-area="month"></div>',
                '<div class="dds__calendar-buttons"></div>',
            ].join("");

            element.querySelector("." + prefix + "datepicker-week").innerHTML = [
                '<span title="Sunday">SUN</span>',
                '<span title="Monday">MON</span>',
                '  <span title="Tuesday">TUE</span>',
                '  <span title="Wednesday">WED</span>',
                '  <span title="Thursday">THU</span>',
                '  <span title="Friday">FRI</span>',
                '  <span title="Saturday">SAT</span>',
            ].join("");

            var bottomMenu = element.querySelector("." + prefix + "calendar-buttons");
            //eslint-disable-next-line
            bottomMenu.innerHTML =
                '<button type="button" class="dds__btn dds__btn-secondary" aria-label="cancel">Cancel</button>';
            bottomMenu.querySelector("." + prefix + "btn-secondary").addEventListener("click", toggleCalendar, false);
            bottomMenu
                .querySelector("." + prefix + "btn-secondary")
                .addEventListener("keydown", buttonKeydownHandler, false);
        },
        setNewDate = function () {
            var deliminator = "/";
            dateInput.value =
                ("0" + (currentSelected.getMonth() + 1)).slice(-2) +
                deliminator +
                ("0" + currentSelected.getDate()).slice(-2) +
                deliminator +
                currentSelected.getFullYear();
            var e = document.createEvent("Event");
            e.initEvent("change", false, false);
            dateInput.dispatchEvent(e);
            dateInput.focus();
            uicoreCustomEvent("DatePicker", "SelectedDate", element, {
                date: currentSelected,
            });
            toggleCalendar();
        },
        setNewTempDate = function (prevDate, currentDate) {
            //look for the new date
            tempSelected = getNextAvailableDay(prevDate, currentDate);
            //remove current selected
            var oldSelected = element.querySelector('[data-calendar-date="' + prevDate + '"]');
            if (oldSelected) {
                oldSelected.children[0].setAttribute("tabindex", "-1");
                oldSelected.classList.remove(prefix + "datepicker-date-temp-selected");
            }
            var newDateElem = element.querySelector('[data-calendar-date="' + tempSelected + '"]');
            var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
            nextBtn.removeAttribute("tabindex");
            nextBtn.parentElement.classList.add(prefix + "datepicker-date-temp-selected");
            nextBtn.focus();
            updateMonthNavLabel(currentDate);
        },
        getNextAvailableDay = function (prevDate, currentDate) {
            var newDate = element.querySelector(
                ":not(.dds__datepicker-date-disabled):not(.dds__datepicker-date-outdated)[data-calendar-date='" +
                    currentDate +
                    "']"
            );
            var direction = prevDate < currentDate ? 1 : -1;
            //check day
            if (newDate) {
                return currentDate;
            } else {
                //check if not before
                if (options.notBeforeDate && currentDate <= options.notBeforeDate) {
                    return prevDate;
                }
                //check if not after
                if (options.notAfterDate && currentDate >= options.notAfterDate) {
                    return prevDate;
                }
                // check if before today
                if (currentDate.getTime() <= todaysDate.getTime() - 1 && !options.pastDates) {
                    return prevDate;
                }
                if (currentDate.getMonth() != prevDate.getMonth()) {
                    if (direction == 1) {
                        monthNext();
                    }
                    if (direction == -1) {
                        monthPrev();
                    }
                    if (
                        element.querySelector(
                            ":not(.dds__datepicker-date-disabled)[data-calendar-date='" + currentDate + "']"
                        )
                    ) {
                        return currentDate;
                    } else {
                        prevDate = new Date(currentDate.getTime());
                        currentDate.setDate(currentDate.getDate() + direction);
                        return getNextAvailableDay(prevDate, currentDate);
                    }
                } else {
                    prevDate = new Date(currentDate.getTime());
                    currentDate.setDate(currentDate.getDate() + direction);
                    return getNextAvailableDay(prevDate, currentDate);
                }
            }
        },
        getNearestAvailableDay = function (currentDate, notBeforeDate, notAfterDate) {
            // the date is valid... so no calculation
            if (checkDateValidity(currentDate)) {
                return currentDate;
            }
            // no limits to find nearest day set limites notBefore=currentDate-1; notAfter=currentDate+30;

            if (!notBeforeDate) {
                notBeforeDate = new Date(currentDate).setDate(currentDate.getDate() - 7);
            }
            if (!notAfterDate) {
                notAfterDate = new Date(currentDate).setDate(currentDate.getDate() + 30);
            }
            // generate the list of possible dates
            var possibleDates = __generateDateRange(notBeforeDate, notAfterDate).filter(checkDateValidity);

            //reducer to find if the $date is on array
            //ignoring timestamp
            var hasDate = function (date) {
                return function (acc, val) {
                    if (!acc && date && val) {
                        acc =
                            val.getDate() === date.getDate() &&
                            val.getMonth() === date.getMonth() &&
                            val.getFullYear() === date.getFullYear();
                    }
                    return acc;
                };
            };

            if (possibleDates.reduce(hasDate(currentDate), false)) {
                //test if the current day is inside available dates
                return currentDate;
            }

            //the first available date closest to currentDate
            return possibleDates.filter(function (value) {
                return value >= currentDate;
            })[0];
        },
        dateKeydownHandler = function (e) {
            if (!e.preventDefault) {
                e.preventDefault = function () {
                    return (e.returnValue = false);
                };
            }
            var prevSelected = new Date(tempSelected.getTime());
            switch (e.keyCode) {
                case keyCode.UP: // up key
                    e.preventDefault();
                    tempSelected.setDate(tempSelected.getDate() - 7);
                    setNewTempDate(prevSelected, tempSelected);
                    break;
                case keyCode.DOWN: // down key
                    e.preventDefault();
                    tempSelected.setDate(tempSelected.getDate() + 7);
                    setNewTempDate(prevSelected, tempSelected);
                    break;
                case keyCode.LEFT: // left key
                    e.preventDefault();
                    tempSelected.setDate(tempSelected.getDate() - 1);
                    setNewTempDate(prevSelected, tempSelected);
                    break;
                case keyCode.RIGHT: // right key
                    e.preventDefault();
                    tempSelected.setDate(tempSelected.getDate() + 1);
                    setNewTempDate(prevSelected, tempSelected);
                    break;
                case keyCode.ESC: // esc key
                    var oldSelected = element.querySelector("." + prefix + "datepicker-date-selected");
                    if (oldSelected) {
                        oldSelected.classList.remove(prefix + "datepicker-date-selected");
                        oldSelected.children[0].setAttribute("tabindex", "-1");
                    }
                    toggleCalendar();
                    break;
                case keyCode.TAB: // tab key
                    if (focusableEls.length === 1) {
                        e.preventDefault();
                        break;
                    }
                    if (e.shiftKey) {
                        if (document.activeElement === focusableEls[0]) {
                            e.preventDefault();
                            focusableEls[focusableEls.length - 1].focus();
                        }
                    } else {
                        if (document.activeElement === focusableEls[focusableEls.length - 1]) {
                            e.preventDefault();
                            focusableEls[0].focus();
                        }
                    }
                    break;
            }
        },
        buttonKeydownHandler = function (e) {
            switch (e.keyCode) {
                case keyCode.ESC: // esc key
                    toggleCalendar();
                    break;
                case keyCode.TAB: // tab key
                    if (focusableEls.length === 1) {
                        e.preventDefault();
                        break;
                    }
                    if (e.shiftKey) {
                        if (document.activeElement === focusableEls[0]) {
                            e.preventDefault();
                            focusableEls[focusableEls.length - 1].focus();
                        }
                    } else {
                        if (document.activeElement === focusableEls[focusableEls.length - 1]) {
                            e.preventDefault();
                            focusableEls[0].focus();
                        }
                    }
                    break;
            }
        },
        inputHandler = function () {
            if (checkDateValidity(new Date(dateInput.value)) && getComputedStyle(errorMsg).display == "block") {
                errorMsg.style.display = "none";
                dateInput.setCustomValidity("");
            } else if (!checkDateValidity(new Date(dateInput.value))) {
                if (
                    !isEdge ||
                    (isEdge && calendar.classList.contains(prefix + "d-none") && document.activeElement !== calendarBtn)
                ) {
                    toggleCalendar();
                }
                if (getComputedStyle(errorMsg).display == "none") {
                    errorMsg.style.display = "block";
                    dateInput.setCustomValidity("Invalid Date");
                }
            }
        },
        submitHandler = function (event) {
            var formValidationErrorSuffix = "Feedback";
            if (form.checkValidity() === false) {
                each(form, function (el) {
                    if (el.tagName == "INPUT") {
                        var errorMsg = form.querySelector("#" + el.getAttribute("id") + formValidationErrorSuffix);
                        setTimeout(function () {
                            if (el.classList.contains("dds__datepicker-input")) {
                                if (checkDateValidity(new Date(el.value))) {
                                    el.setCustomValidity("");
                                } else {
                                    el.setCustomValidity("Invalid Date");
                                }
                            }
                            if (!el.validity.valid) {
                                //if error message has not been turned on yet
                                if (errorMsg && !(getComputedStyle(errorMsg)["display"] == "block")) {
                                    errorMsg.style.display = "block";
                                }
                                el.setAttribute("aria-invalid", "true");
                                el.setAttribute("aria-describedby", el.getAttribute("id") + formValidationErrorSuffix);
                            } else {
                                //if error message has not been turned off yet
                                if (errorMsg && getComputedStyle(errorMsg)["display"] == "block") {
                                    errorMsg.style.display = "none";
                                }
                                el.setAttribute("aria-invalid", "false");
                                el.setAttribute("aria-describedby", "");
                            }
                        }, 10);
                    }
                });
                event.preventDefault();
                event.stopPropagation();
            }

            form.classList.add("dds__was-validated");
        };
    //public functions
    this.init = function () {
        createCalendar();
        options.button_prev = document.querySelector(target + " [data-calendar-toggle=previous]");
        options.button_next = document.querySelector(target + " [data-calendar-toggle=next]");
        monthEl = document.querySelector(target + " [data-calendar-area=month]");
        monthLabel = document.querySelector(target + " [data-calendar-label=month]");
        datePtr.setDate(1);
        createMonth();
        calendarWrapper = element.querySelector("." + prefix + "calendar-wrapper")
            ? element.querySelector("." + prefix + "calendar-wrapper")
            : createWrapper();
        options.button_prev.addEventListener(
            "click",
            function () {
                monthPrev();
                removeTempClass();
                var prevMonthDate = new Date(tempSelected.getTime());
                prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
                prevMonthDate.setDate(1);
                setNewTempDate(
                    prevMonthDate,
                    getNearestAvailableDay(prevMonthDate, options.notBeforeDate, options.notAfterDate)
                );
                if (!options.button_prev.classList.contains(prefix + "disabled")) {
                    options.button_prev.focus();
                }
                updateMonthNavLabel(prevMonthDate);
            },
            false
        );
        options.button_next.addEventListener(
            "click",
            function () {
                monthNext();
                removeTempClass();
                var nextMonthDate = new Date(tempSelected.getTime());
                nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
                nextMonthDate.setDate(1);
                setNewTempDate(
                    nextMonthDate,
                    getNearestAvailableDay(nextMonthDate, options.notBeforeDate, options.notAfterDate)
                );
                if (!options.button_next.classList.contains(prefix + "disabled")) {
                    options.button_next.focus();
                }
                updateMonthNavLabel(nextMonthDate);
            },
            false
        );
        updateMonthNavLabel(tempSelected);
        options.button_prev.addEventListener("keydown", buttonKeydownHandler, false);
        options.button_next.addEventListener("keydown", buttonKeydownHandler, false);
    };

    this.destroy = function () {
        options.button_prev.removeEventListener("click", monthPrev, false);
        options.button_next.removeEventListener("click", monthNext, false);
        options.button_prev.removeEventListener("keydown", buttonKeydownHandler, false);
        options.button_next.removeEventListener("keydown", buttonKeydownHandler, false);
        clearCalendar();
        document.querySelector(target).innerHTML = "";
    };
    this.reset = function () {
        this.destroy();
        this.init();
    };
    // this.set = function (options) {
    //     for (var k in options) {
    //         if (opts.hasOwnProperty(k)) {
    //             opts[k] = options[k];
    //         }
    //     }
    //     createMonth();
    // }
    if (!(stringDatePicker in element)) {
        // validate options
        if (calendarBtn == null) {
            throw new Error("There was a problem found with date picker button, please correct and try again");
        }
        if (target == null) {
            throw new Error("There was a problem found with date picker target, please correct and try again");
        }
        if (calendar == null) {
            throw new Error("There was a problem found with calendar, please correct and try again");
        }
        //remove time from date
        datePtr.setHours(0, 0, 0, 0);
        todaysDate.setHours(0, 0, 0, 0);
        calendarBtn.addEventListener("click", toggleCalendar, false);
        if (isEdge) {
            calendarBtn.addEventListener("click", inputHandler, false);
            dateInput.addEventListener("focusout", inputHandler, false);
        }
        if (!dateInput.hasAttribute("placeholder")) {
            console.warn(
                "Placeholder was not set. Please set a placeholder value if you plan to use form validation. Setting to default placeholder value."
            );
            dateInput.setAttribute("placeholder", "__/__/____");
        }
        if (dateInput) {
            new InputMask(dateInput);
            var errorMsg = element.querySelector("." + prefix + "invalid-feedback");
            dateInput.addEventListener("change", inputHandler, false);
        }
        form = getClosest(element, "FORM", false);
        form.addEventListener("submit", submitHandler);
        setFocusableElements();
        // //set input mask
        options.defaultDate = options.defaultDate
            ? checkDateValidity(new Date(options.defaultDate))
                ? options.defaultDate
                : ""
            : "";
        if (!options.defaultDate) {
            options.defaultDate = new Date();
            options.defaultDate.setHours(0, 0, 0, 0);
        } else {
            var defaultDate = (options.defaultDate = new Date(options.defaultDate));
            var defaultDay = defaultDate.getDate() < 10 ? "0" + defaultDate.getDate() : defaultDate.getDate();
            var defaultMonth =
                defaultDate.getMonth() + 1 < 10 ? "0" + (defaultDate.getMonth() + 1) : defaultDate.getMonth() + 1;

            dateInput.setAttribute("value", defaultMonth + "/" + defaultDay + "/" + defaultDate.getFullYear());
            var e = document.createEvent("Event");
            e.initEvent("change", false, false);
            dateInput.dispatchEvent(e);
            if (isIE || isEdge) {
                element.style.display = "none"; //IE needs a small timeout or cursor will appear in input box
                setTimeout(function () {
                    dateInput.blur();
                    element.style.display = "block";
                }, 300);
            }
        }
        this.init();
    }
    element[stringDatePicker] = self;
}

export function BarSelect(element) {
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    var self = this,
        stringBarSelect = "BarSelect",
        selects = null,
        value = 0,
        clickHandler = function (e) {
            var input = e.target.classList.contains(prefix + "bar-select")
                ? e.target.querySelector("input")
                : e.target.tagName == "LABEL"
                ? e.target.previousElementSibling
                : e.target;
            value = parseInt(input.getAttribute("value"));
            each(selects, function (select) {
                if (parseInt(select.querySelector("input").getAttribute("value")) <= value) {
                    select.classList.remove(prefix + "bar-select-hover");
                    select.classList.add(prefix + "bar-select-selected");
                } else {
                    select.classList.remove(prefix + "bar-select-hover");
                    select.classList.remove(prefix + "bar-select-selected");
                }
            });
            uicoreCustomEvent("BarSelect", "SelectedValue", element, {
                value: value,
            });
        },
        mouseOverHandler = function (e) {
            var input = e.target.classList.contains(prefix + "bar-select")
                ? e.target.querySelector("input")
                : e.target.tagName == "LABEL"
                ? e.target.previousElementSibling
                : e.target;
            value = parseInt(input.getAttribute("value"));
            each(selects, function (select) {
                if (parseInt(select.querySelector("input").getAttribute("value")) <= value) {
                    select.classList.add(prefix + "bar-select-hover");
                } else {
                    select.classList.remove(prefix + "bar-select-hover");
                }
            });
        },
        mouseOutHandler = function () {
            each(selects, function (select) {
                select.classList.remove(prefix + "bar-select-hover");
            });
        },
        focusHandler = function (e) {
            var input = e.currentTarget.nextElementSibling;
            if (!isInViewport(input)) {
                input.scrollIntoView(false);
            }
        },
        isInViewport = function (element) {
            var bounding = element.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

    if (!(stringBarSelect in element)) {
        selects = element.querySelectorAll("." + prefix + "bar-select");
        element.addEventListener("mouseout", mouseOutHandler, false);
        each(selects, function (select) {
            select.addEventListener("click", clickHandler, false);
            select.addEventListener("mouseover", mouseOverHandler, false);
            if (isIE || isFirefox) {
                select.querySelector("input").addEventListener("focusin", focusHandler, false);
            }
        });
    }

    element[stringBarSelect] = self;
}
