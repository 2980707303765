import {
    getClosest,
    globalObject,
    DOC,
    prefix,
    styleTip,
    emulateTransitionEnd,
    uicoreCustomEvent,
    createElement,
    validateNum,
    jsonOptionsInit,
    isIOS,
} from "./utilities.js";

export default function Tooltip(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    var textAlignments = ["left", "center", "right"];
    // init options
    (options.title = element.dataset.title ? element.dataset.title : options.data_title ? options.data_title : null),
        (options.animation =
            options.animation && options.animation === "boolean"
                ? options.animation
                : options.animation && options.animation === prefix + "fade"
                ? prefix + "fade"
                : false);
    options.placement = element.dataset.placement
        ? element.dataset.placement
        : options.data_placement
        ? options.data_placement
        : null;
    options.textAlign =
        element.dataset.text_align && textAlignments.indexOf(element.dataset.text_align.toLowerCase()) > -1
            ? element.dataset.text_align.toLowerCase()
            : options.text_align && textAlignments.indexOf(options.text_align.toLowerCase()) > -1
            ? options.text_align.toLowerCase()
            : "center";
    options.delay = element.dataset.delay ? validateNum(element.dataset.delay, 60) : validateNum(options.delay, 60);
    options.container = element.dataset.container
        ? element.dataset.container
        : options.container
        ? options.container
        : DOC.body;

    // validate options
    (function () {
        if (options.title == null) {
            throw new Error("There was a problem found with title value, please correct and try again");
        }
        if (options.placement == null) {
            throw new Error("There was a problem found with placement value, please correct and try again");
        }

        var inFixedTop = getClosest(element, ".fixed-top");
        if (inFixedTop) {
            options.container = inFixedTop;
        } else {
            var inFixedBottom = getClosest(element, ".fixed-bottom");
            if (inFixedBottom) {
                options.container = inFixedBottom;
            }
        }
    })();

    // bind, event targets, title and constants
    var self = this,
        stringTooltip = "Tooltip",
        mouseHover = "onmouseleave" in DOC ? ["mouseenter", "mouseleave"] : ["mouseover", "mouseout"],
        tooltip,
        tooltipArrow,
        tooltipId,
        createToolTip = function () {
            tooltip = createElement("div", {
                role: "tooltip",
                aria_describedby: tooltipId,
            });
            tooltip.style["top"] = "0px"; //move tooltip to top to prevent scrollbar during calculation

            // tooltip arrow
            tooltipArrow = createElement("div", {
                class: prefix + "arrow " + prefix + "position-absolute " + prefix + "d-block",
            });

            tooltip.appendChild(tooltipArrow);
            // tooltip inner

            var tooltipInner = createElement("div", {
                class: prefix + "tooltip-inner " + prefix + "text-" + options.textAlign,
                html: options.title,
                id: tooltipId,
            });
            tooltip.appendChild(tooltipInner);

            options.container.appendChild(tooltip);
            tooltip.setAttribute(
                "class",
                prefix +
                    "tooltip " +
                    prefix +
                    "bs-tooltip" +
                    "-" +
                    options.placement +
                    " " +
                    options.animation +
                    " " +
                    prefix +
                    "position-absolute " +
                    prefix +
                    "d-block " +
                    prefix +
                    "break-word"
            );
        },
        updateTooltip = function () {
            tooltip.setAttribute("style", "");
            tooltipArrow.setAttribute("style", "");
            styleTip(element, tooltip, options.placement, options.container, true);
        },
        showTooltip = function () {
            !tooltip.classList.contains(prefix + "show") && tooltip.classList.add(prefix + "show");
        },
        // triggers
        showTrigger = function () {
            globalObject.addEventListener("resize", self.hide, false);
            uicoreCustomEvent("Tooltip", "Shown", element);
        },
        hideTrigger = function () {
            globalObject.removeEventListener("resize", self.hide);
            uicoreCustomEvent("Tooltip", "Hidden", element);
        },
        keyHandler = function (e) {
            var key = e.which || e.keyCode;
            if (key === 27) {
                self.hide(e);
            }
        },
        clickHandler = function (e) {
            if (!tooltip.classList.contains(prefix + "show")) {
                self.show();
            } else {
                self.hide(e);
            }
        },
        touchHandler = function (e) {
            if (tooltip.classList.contains(prefix + "show")) {
                if ((!e.target === tooltip || !tooltip.contains(e.target)) && element !== e.target) {
                    self.hide();
                }
            }
        },
        setTooltipId = function () {
            var tooltipIdText = options.title
                .replace(/[\s\W]/g, "")
                .toLowerCase()
                .substring(0, 15);
            tooltipId = tooltipIdText;
            var counter = 1;
            while (DOC.getElementById(tooltipId)) {
                tooltipId = tooltipIdText + counter;
                counter++;
            }
        };

    // public methods
    this.show = function () {
        element.setAttribute("aria-expanded", true);

        setTimeout(function () {
            updateTooltip();
            showTooltip();
            uicoreCustomEvent("Tooltip", "ShowEvent", element);
            options.animation ? emulateTransitionEnd(tooltip, showTrigger) : showTrigger();
        }, options.delay);
    };

    this.hide = function (e) {
        element.setAttribute("aria-expanded", false);

        var target = DOC.activeElement;
        if (e && e.relatedTarget) {
            target = e.relatedTarget;
        }

        if (!target === tooltip || !tooltip.contains(target)) {
            setTimeout(function () {
                if (tooltip && tooltip.classList.contains(prefix + "show")) {
                    uicoreCustomEvent("Tooltip", "HideEvent", element);
                    tooltip.classList.remove(prefix + "show");
                    options.animation ? emulateTransitionEnd(tooltip, hideTrigger) : hideTrigger();
                }
            }, options.delay);
        }
    };

    this.toggle = function () {
        if (!tooltip) {
            self.show();
        } else {
            self.hide();
        }
    };

    // init
    if (!(stringTooltip in element)) {
        setTooltipId();

        element.setAttribute("aria-describedby", tooltipId);
        element.setAttribute("aria-expanded", false);
        element.setAttribute("data-original-title", options.title);
        element.setAttribute("data-tooltip-id", tooltipId);
        element.removeAttribute("title");

        element.addEventListener(mouseHover[0], self.show, false);
        element.addEventListener(mouseHover[1], self.hide, false);

        element.addEventListener("focus", self.show);
        element.addEventListener("blur", self.hide);

        element.addEventListener("keydown", keyHandler);

        // Click event is being used because screen readers intercept keydown
        // Enter key is not fired in normal mode, only in focus mode
        element.addEventListener("click", clickHandler, false);

        if (isIOS) {
            element.addEventListener("click", self.show, false);
            window.addEventListener("touchstart", touchHandler, false);
        }

        if (!tooltip) {
            createToolTip();
            tooltip.addEventListener("mouseleave", self.hide, false);
        }
    }

    element[stringTooltip] = self;
}
