import {
    DOC,
    emulateTransitionEnd,
    globalObject,
    getClosest,
    prefix,
    styleTip,
    uicoreCustomEvent,
    getFullScreenOverlay,
    createElement,
    jsonOptionsInit,
} from "./utilities.js";

export default function Popover(element, options) {
    // initialization element
    element =
        element instanceof HTMLElement
            ? element
            : (function () {
                  return false;
              })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.delay = element.dataset.delay
        ? parseInt(element.dataset.delay)
        : options.delay
        ? parseInt(options.delay)
        : 10;
    options.title = element.dataset.title ? element.dataset.title : options.data_title ? options.data_title : null;
    options.content = element.dataset.content
        ? element.dataset.content
        : options.data_content
        ? options.data_content
        : null;
    options.placement = element.dataset.placement
        ? element.dataset.placement
        : options.data_placement
        ? options.data_placement
        : null;
    options.container = element.dataset.container
        ? element.dataset.container
        : options.container
        ? options.container
        : DOC.body;

    // validate options
    (function () {
        if (options.title == null || options.title === "") {
            throw new Error("There was a problem found with title value, please correct and try again");
        }
        if (options.content == null || options.content === "") {
            throw new Error("There was a problem found with content value, please correct and try again");
        }
        if (options.placement == null) {
            throw new Error("There was a problem found with placement value, please correct and try again");
        }
        var inOffCanvas = getClosest(element, "." + prefix + "modal-offcanvas");
        if (inOffCanvas) {
            options.container = inOffCanvas;
        } else {
            var inFixedTop = getClosest(element, ".fixed-top");
            if (inFixedTop) {
                options.container = inFixedTop;
            } else {
                var inFixedBottom = getClosest(element, ".fixed-bottom");
                if (inFixedBottom) {
                    options.container = inFixedBottom;
                }
            }
        }
    })();

    // DATA API
    var self = this,
        popoverString = "Popover",
        container = DOC.body,
        overlay = getFullScreenOverlay(),
        popoverArrow = null,
        popoverCloseBtn = null,
        popoverFocusTrap = null,
        positionCalc = true,
        smallMedia = window.matchMedia("(max-width: 767.98px)"),
        popover = null,
        popoverModal = null,
        visible = false,
        isHiding = false,
        showTimeout = null,
        hideTimeout = null,
        // handlers
        triggerHandler = function (e) {
            self.toggle();
            e.preventDefault();
        },
        blurHandler = function (e) {
            // setTimeout is important here because when you tab with keyboard,
            // activeElement is not consistently updated until the blur event has been processed
            setTimeout(function () {
                if (DOC.activeElement === element) return;
                if (popover && !popover.contains(DOC.activeElement)) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            }, 10);
        },
        windowBlurHandler = function () {
            self.hide();
        },
        closeBtnHandler = function (e) {
            self.hide();
            element.focus();
            e.preventDefault();
            e.stopPropagation();
        },
        focusTrapHandler = function (e) {
            self.hide();
            element.focus();
            e.preventDefault();
        },
        keyHandler = function (e) {
            if (e.keyCode === 27 && e.type === "keydown") {
                //esc
                element.focus();
                self.hide();
                e.preventDefault();
            } else if (e.keyCode === 13 && e.type === "keydown") {
                //enter
                if (e.currentTarget.classList.contains(prefix + "close")) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            } else if ((e.keyCode === 9) & (e.type === "keydown")) {
                //tab out
                if (e.shiftKey && e.srcElement.classList.contains(prefix + "popover")) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            }
        },
        createPopover = function () {
            //create popover divs
            var popoverDialog = null,
                popoverTitleDiv = null,
                popoverTitle = null,
                popoverContent = null,
                popoverParagraph = null;

            popover = createElement("div", {
                class: prefix + "modal-dialog",
                tabindex: "0",
                role: "dialog",
            });

            popoverDialog = createElement("div", {
                class: prefix + "popover-dialog",
            });
            popover.appendChild(popoverDialog);

            //create popover arrow
            popoverArrow = createElement("div", {
                class: prefix + "arrow",
            });
            popover.appendChild(popoverArrow);

            //set popover title container
            popoverTitleDiv = createElement("div", {
                class: prefix + "popover-title-container " + prefix + "d-flex",
            });

            popoverDialog.appendChild(popoverTitleDiv);

            popoverTitle = createElement("h3", {
                class: prefix + "popover-header " + prefix + "bold-16",
                html: options.title,
            });
            popoverTitleDiv.appendChild(popoverTitle);

            //set popover body content
            popoverContent = createElement("div", {
                class: prefix + "popover-body",
            });

            popoverParagraph = createElement("p", {
                html: options.content,
            });

            popoverDialog.appendChild(popoverContent);
            popoverContent.appendChild(popoverParagraph);

            //create close button
            popoverCloseBtn = createElement("button", {
                class: prefix + "close" + " " + prefix + "icons " + prefix + "close-x " + prefix + "position-absolute",
                tabindex: "0",
                aria_label: "Close Popover",
                data_dismiss: prefix + "popover",
            });

            popoverTitleDiv.appendChild(popoverCloseBtn);

            popoverFocusTrap = createElement("div", {
                tabindex: "0",
            });

            popover.appendChild(popoverFocusTrap);

            //append to the container
            if (smallMedia.matches) {
                popoverModal = createElement("div", {
                    class: prefix + "modal",
                });
                popoverModal.style.display = "block";

                DOC.body.classList.add(prefix + "modal-open");
                popoverModal.appendChild(popover);
                element.parentNode.insertBefore(popoverModal, element);
            } else {
                container.insertBefore(popover, container.firstChild);
            }

            var popoverClasses = [
                prefix + "popover",
                prefix + "bs-popover-" + options.placement,
                prefix + "fade",
                prefix + "rounded-0",
            ];

            popover.setAttribute("class", popoverClasses.join(" "));
        },
        removePopover = function () {
            var popoverParent = popover.parentNode;

            // if popover has the dds__modal, then access it's parent to remove the whole node
            if (popoverParent.getAttribute("class") === prefix + "modal") {
                popoverParent.parentNode.removeChild(popoverParent);
            } else {
                container.removeChild(popover);
            }
            popover = null;
            DOC.body.classList.remove(prefix + "modal-open");
        },
        createOverlay = function () {
            if (overlay) {
                overlay.classList.add(prefix + "show");
                overlay.removeAttribute("hidden");
                overlay.style.visibility = "hidden";
            } else {
                console.warn(
                    "POPOVER: Overlay requested. Corresponding HTML missing. Please apply 'dds__overlay' to a div"
                );
            }
        },
        removeOverlay = function () {
            if (overlay) overlay.classList.remove(prefix + "show");
        },
        showPopover = function () {
            if (!popover.classList.contains(prefix + "show")) popover.classList.add(prefix + "show");
        },
        hidePopover = function () {
            if (popover.classList.contains(prefix + "show")) popover.classList.remove(prefix + "show");
        },
        updatePopover = function () {
            if (positionCalc) {
                styleTip(element, popover, options.placement, container);
            } else {
                popover.style.cssText = "";
            }
        },
        // triggers
        showTrigger = function () {
            uicoreCustomEvent("Popover", "Shown", element);
        },
        hideTrigger = function () {
            if (visible) removePopover();
            visible = false;
            isHiding = false;
            uicoreCustomEvent("Popover", "Hidden", element);
        },
        positionCalculation = function (e) {
            if (e.matches) {
                positionCalc = false;
            } else {
                positionCalc = true;
            }
        };

    // public methods / handlers
    this.toggle = function () {
        if (visible) {
            self.hide();
        } else {
            self.show();
        }
    };

    this.show = function () {
        if (visible || isHiding) return;
        visible = true;

        createPopover();
        createOverlay();
        updatePopover();

        clearTimeout(showTimeout);
        showTimeout = setTimeout(function () {
            showPopover();

            popover.focus();
            popover.addEventListener("blur", blurHandler, false);
            popover.addEventListener("keydown", keyHandler, false);
            popoverFocusTrap.addEventListener("focus", focusTrapHandler, false);
            popoverCloseBtn.addEventListener("click", closeBtnHandler, false);

            window.addEventListener("blur", windowBlurHandler, false);
            globalObject.addEventListener("resize", self.toggle, false);

            uicoreCustomEvent("Popover", "ShowEvent", element);
            emulateTransitionEnd(popover, showTrigger);
        }, options.delay);
    };

    this.hide = function () {
        if (!visible || isHiding) return;
        isHiding = true;

        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(function () {
            hidePopover();
            removeOverlay();

            popover.removeEventListener("blur", blurHandler, false);
            popover.removeEventListener("keydown", keyHandler, false);
            popoverFocusTrap.removeEventListener("focus", focusTrapHandler, false);
            popoverCloseBtn.removeEventListener("click", closeBtnHandler, false);

            window.removeEventListener("blur", windowBlurHandler, false);
            globalObject.removeEventListener("resize", self.toggle, false);

            uicoreCustomEvent("Popover", "HideEvent", element);
            emulateTransitionEnd(popover, hideTrigger);
        }, options.delay);
    };

    this.update = function () {
        updatePopover();
    };

    // init
    if (!(popoverString in element)) {
        // prevent adding event handlers twice
        element.addEventListener("click", triggerHandler, false);
        positionCalculation(smallMedia);
        smallMedia.addListener(positionCalculation);
    }

    element[popoverString] = self;
}
